<template>
  <transition
    :css="false"
    @before-leave="beforeLeave">
    <v-tour
      :steps="steps"
      :callbacks="callbacks"
      :options="options"
      name="FirstLoginTour">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :class="'first-login-' + index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            class="tour-step">
            <div slot="actions">
              <button
                class="end-tour-btn"
                @click="tour.stop">
                <i class="remove-icon-default" />
              </button>
              <div class="tour-footer">
                <span class="flex-item">{{ index + 1 }}/{{ tour.steps.length }}</span>
                <div class="flex-item tour-nav">
                  <button
                    :class="{
                      'disabled': [0, 2, 3, 4].includes(tour.currentStep)
                    }"
                    @click="customPreviousStep(index, tour.previousStep)">
                    <i class="fa fa-angle-left" />
                  </button>
                  <button
                    :class="{
                      'disabled': !enabledSteps.includes(tour.currentStep)
                    }"
                    @click="tour.nextStep">
                    <i class="fa fa-angle-right" />
                  </button>
                </div>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      callbacks: {
        onStart: this.startCallback,
        onStop: this.stopCallback,
        onNextStep: this.nextStepCallBack,
        onPreviousStep: this.previousStepCallback
      },
      options: {
        useKeyboardNavigation: false
      }
    }
  },
  computed: {
    ...mapState('tours', ['FirstLoginTour', 'savedView']),
    ...mapState('views', ['activeShipmentsView', 'views']),
    ...mapState('auth', ['user']),
    ...mapState('userSubscriptions', ['userHasAnalyticsSubscription']),
    steps() {
      const steps = [
        {
          target: '.first-login-tour-0',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='documents-icon'></i></span></div><div class='flex-item'><p>As you log in, you'll be able to see a variety of shipments right away</p></div></div>",
          offset: -300
        },
        {
          target: '.first-login-tour-1',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='fa fa-search'></i></span></div><div class='flex-item'><p>To begin searching, enter your keyword here</p></div></div>"
        },
        {
          target: '.first-login-tour-2',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='field-lists-icon'></i></span></div><div class='flex-item'><p>You can select which field you'd like to search in</p></div></div>",
          params: {
            placement: 'right'
          }
        },
        {
          target: '.first-login-tour-3',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='lightbulb-idea-icon'></i></span></div><div class='flex-item'><p>You will also receive Suggestions based on the data available</p></div></div>",
          params: {
            placement: 'right'
          }
        },
        {
          target: '.first-login-tour-4',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='rocket-icon'></i></span></div><div class='flex-item'><p>Once you've selected your keyword, select New Search to run your query</p></div></div>",
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '.first-login-tour-5',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='documents-icon'></i></span></div><div class='flex-item'><p>You'll see the results here</p></div></div>",
          offset: -200
        },
        {
          target: '.first-login-tour-6',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='filter-icon'></i></span></div><div class='flex-item'><p>You can even Refine Results as you see the data</p></div></div>",
          params: {
            placement: 'left'
          }
        },
        {
          target: '.first-login-tour-7',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='data-list-icon'></i></span></div><div class='flex-item'><p>You can also change your Fields Viewed here</p></div></div>",
          params: {
            placement: 'top'
          },
          offset: -300
        },
        {
          target: '.first-login-tour-8',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='user-card-icon'></i></span></div><div class='flex-item'><p>To get a detailed view of the shipment, click on the product's Bill of Lading Number or Magnifying Glass</p></div></div>",

          offset: -500
        },
        {
          target: '.first-login-tour-9',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='user-profile-icon'></i></span></div><div class='flex-item'><p>We've also got Company Profiles available now</p></div></div>",

          offset: -500
        },
        {
          target: '.first-login-tour-10',
          content:
            "<div class='flex-container'><div class='flex-item'><span class='circle'><i class='analytics-icon'></i></span></div><div class='flex-item'><p>You can even create Analytics instantly in the App now.</p></div></div>",
          offset: -200
        }
      ]
      if (!this.userHasAnalyticsSubscription) {
        return steps.filter(step => step.target !== '.first-login-tour-10')
      }

      return steps
    },
    enabledSteps() {
      if (!this.userHasAnalyticsSubscription) {
        return [0, 5, 6, 7, 8]
      }

      return [0, 5, 6, 7, 8, 9, 11]
    }
  },
  mounted() {
    let currentStep = this.FirstLoginTour.currentStep
    currentStep = currentStep === -1 ? 0 : currentStep

    this.setTourStep({
      tour: 'FirstLoginTour',
      step: currentStep
    })
    this.setTourActive(true)

    this.$tours.FirstLoginTour.start(currentStep)
  },
  methods: {
    ...mapMutations('tours', [
      'setTourActive',
      'toggleTour',
      'setTourStep',
      'saveView'
    ]),
    ...mapMutations('views', ['setActiveShipmentsView']),
    beforeLeave() {
      this.setTourActive(false)

      if (this.$tours.FirstLoginTour.currentStep === this.steps.length - 1) {
        this.$tours.FirstLoginTour.stop()
      }
    },
    customPreviousStep(currentStep, callback) {
      if (currentStep === 5) {
        this.$tours.FirstLoginTour.currentStep = 2
      }

      callback()
    },
    startCallback() {
      const firstView = this.views[0]

      this.saveView(this.activeShipmentsView)
      this.setActiveShipmentsView(firstView)
    },
    stopCallback() {
      if (this.savedView) {
        this.setActiveShipmentsView(this.savedView)
        this.saveView(null)
      }

      this.setTourStep({
        tour: 'FirstLoginTour',
        step: -1
      })

      this.toggleTour({
        tour: 'FirstLoginTour',
        show: false
      })

      this.updateLoginTourState()
    },
    updateLoginTourState() {
      if (!this.user.login_tour_state) {
        this.$axios.$put(
          `${USERS_API_URL}/client/${
            this.user.clientId
          }/details/login-tour-state`,
          { state: 1 },
          { progress: false }
        )

        this.$auth.$storage.setState('user', {
          ...this.user,
          login_tour_state: 1
        })
      }
    },
    nextStepCallBack(step) {
      const currentStep = step + 1

      this.setTourStep({
        tour: 'FirstLoginTour',
        step: currentStep
      })
    },
    previousStepCallback(step) {
      const currentStep = step - 1

      this.setTourStep({
        tour: 'FirstLoginTour',
        step: currentStep
      })
    }
  }
}
</script>

<style src="~/assets/scss/components/tours/first-login-tour.scss" lang="scss"/>
