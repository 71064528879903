<template>

  <!-- This component is currently not used and is outdated. -->

  <ul class="base-checkbox">
    <li
      v-for="(refinement, index) in refinements.filter(refinement => refinement.name)"
      :class="{
        reset: isWithinSelection(refinement) && selection.type === '',
        included: isWithinSelection(refinement) && selection.type === 'include',
        excluded: isWithinSelection(refinement) && selection.type === 'exclude'
      }"
      :key="refinement.name"
      tabindex="0"
      @click.exact="onItemClick(refinement)"
      @click.shift="select(index, getCondition(refinement) ? '' : 'include')"
      @keydown.enter="onEnter($event, refinement, index)">

      <refined-by-checkbox
        :refinement="refinement"
        :condition="getCondition(refinement)"
        @toggleWithShift="select(index, $event)"
        @changeCondition="
          toggleRefinedBy({
            field: field,
            name: refinement.name,
            condition: $event
          })
        "/>
    </li>
    <li v-if="refinements.length === 0">No refinements available.</li>
  </ul>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { inRange } from '~/utils/number'
import RefinedByCheckbox from '~/components/common/RefinedByCheckbox'

export default {
  components: {
    RefinedByCheckbox
  },
  props: {
    refinements: {
      type: Array,
      default: () => []
    },
    field: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selection: {
        type: 'include',
        start: null,
        end: null
      }
    }
  },
  computed: {
    ...mapState('search', {
      refinedBy: state => state.refinedBy
    })
  },
  methods: {
    ...mapMutations('search', [
      'setRefinedBy',
      'removeRefinedBy',
      'setSelectedRefinedByMobile'
    ]),
    ...mapActions('search', [
      'fetchShipmentsAndShipmentAggregates',
      'fetchRefinedBy',
      'fetchSearchHistory',
      'fetchShipmentGraph',
      'resetCurrentPageOfAllViews'
    ]),
    ...mapActions('analytics', ['fetchAnalyticsData']),
    onEnter(event, refinement, index) {
      return event.shiftKey
        ? this.select(index, this.getCondition(refinement) ? '' : 'include')
        : this.onItemClick(refinement)
    },
    onReleaseShift({ keyCode }) {
      if (keyCode === 16) {
        this.checkSelection()
      }
    },
    getCondition(refinement) {
      const refiner = this.refinedBy.find(
        r =>
          r.field === this.field &&
          r.name.toLowerCase() === refinement.name.toLowerCase()
      )

      return refiner ? refiner.condition : ''
    },
    checkSelection() {
      document.removeEventListener('keyup', this.onReleaseShift)
      const type = this.selection.type
      const start = Math.min(this.selection.start, this.selection.end)
      const end = Math.max(this.selection.start, this.selection.end)

      this.refinements.slice(start, end + 1).forEach(refinement => {
        const payload = {
          field: this.field,
          name: refinement.name,
          condition: type
        }

        if (type === '') {
          this.removeRefinedBy(payload)
        } else {
          this.setRefinedBy(payload)
        }
      })

      this.selection.type = 'include'
      this.selection.start = null
      this.selection.end = null

      this.refresh()
    },
    select(index, condition) {
      if (this.selection.start === null) {
        this.selection.type = condition
        this.selection.start = index
        this.selection.end = index

        return document.addEventListener('keyup', this.onReleaseShift)
      }

      this.selection.end = index
    },
    isWithinSelection(refinement) {
      if (this.selection.start === null || this.selection.end === null) {
        return false
      }

      return inRange(
        this.refinements.indexOf(refinement),
        this.selection.start,
        this.selection.end,
        true
      )
    },
    toggleRefinedBy(payload) {
      if (payload.condition) {
        this.setRefinedBy(payload)
      } else {
        this.removeRefinedBy(payload)
      }

      this.refresh()
    },
    onItemClick(refinement) {
      const condition = this.getCondition(refinement)
      const payload = {
        field: this.field,
        name: refinement.name,
        initialCondition: condition,
        condition: condition ? '' : 'include'
      }
      if (!this.$device.isMobile) {
        return this.toggleRefinedBy(payload)
      }

      this.setSelectedRefinedByMobile(payload)
    },
    refresh() {
      this.fetchRefinedBy()
      this.resetCurrentPageOfAllViews()
      this.fetchShipmentGraph()
      this.fetchAnalyticsData()
      this.fetchShipmentsAndShipmentAggregates().then(() => {
        this.fetchSearchHistory()
      })
    }
  }
}
</script>
