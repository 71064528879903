<template>
  <data-viewer
    :parent-name="parentName"
    :is-visible="isVisible"
    :loading="loading"
    :data="dataToDataTableView"
    :headers="headers"
    :sort-field="sortField"
    :limit="limit"
    :total="total"
    :page-limit="pageLimit"
    :sort-order="sortOrder"
    :current-page="currentPage"
    :hide-pagination="hidePagination"
    :options="{
      showCards: false,
      showFullScreen: false
    }"
    :has-checkboxes="hasCheckboxes"
    :disabled-column-resizer="[0,1]"
    :floating-scrollbar-options="floatingScrollbarOptions"
    :class="dataTableClass"
    @sort="$emit('sort', $event)"
    @limit="$emit('limit', $event)"
    @page="$emit('page', $event)"
    @rows-selected="$emit('rows-selected', $event)">

    <!--Header slot: Template Selection -->
    <div
      slot="headerLeft"
      class="data-viewer__header">
      <div class="view-title">
        <h1 class="transform-none">Consignee results {{ dateRangeText }}</h1>
      </div>
    </div>
  </data-viewer>
</template>

<script>
import dataViewerProps from './DataViewer/data-viewer-props'
import DataViewer from './DataViewer/DataViewer'
import { mapState } from 'vuex'
import { numericalFields } from '~/utils/numericalFields'
import md5 from 'md5'

export default {
  name: 'SearchSelectedDataViewer',
  components: { DataViewer },
  props: {
    ...dataViewerProps,
    dateRangeText: {
      required: false,
      type: String,
      default: ''
    },
    hasBookmark: {
      type: Boolean,
      default: true
    },
    dataProp: {
      required: true,
      type: Array,
      default: () => []
    },
    activeAggregateView: {
      required: true,
      type: String,
      default: ''
    },
    hidePagination: {
      required: false,
      type: Boolean,
      default: false
    },
    sortable: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['country', 'tradeType']),
    ...mapState('views', ['fields', 'activeShipmentsView']),
    headerShippers() {
      return [
        { id: 'index', text: '#' },
        { id: 'Shipper', text: 'Shipper', sortable: true },
        { id: 'ShipmentCount', text: 'Shipments', sortable: true },
        { id: 'NumberOfContainers', text: 'Containers', sortable: true },
        { id: 'GrossWeightKgs', text: 'Gross Weight (kg)', sortable: true },
        { id: 'CalculatedTEU', text: 'TEU', sortable: true },
        { id: 'ShipperAddress', text: 'Shipper Address', sortable: true }
      ]
    },
    headerConsignees() {
      return [
        { id: 'index', text: '#' },
        { id: 'Consignee', text: 'Consignee', sortable: true },
        { id: 'ShipmentCount', text: 'Shipments', sortable: true },
        { id: 'NumberOfContainers', text: 'Containers', sortable: true },
        { id: 'GrossWeightKgs', text: 'Gross Weight (kg)', sortable: true },
        { id: 'CalculatedTEU', text: 'TEU', sortable: true },
        { id: 'ConsigneeState', text: 'Consignee State', sortable: true },
        { id: 'ConsigneeCity', text: 'Consignee City', sortable: true }
      ]
    },
    headerShipments() {
      const fields = this.activeShipmentsView.items.map(
        this.makeHeaderFromField
      )

      return [
        {
          id: 'index',
          text: '#'
        },
        ...fields
      ]
    },
    headers() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.headerConsignees
        case 'shippers':
          return this.headerShippers
        case 'shipments':
        case 'overview':
          return this.headerShipments
        default:
          return []
      }
    },
    dataToDataTableView() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.dataConsignees()
        case 'shippers':
          return this.dataShippers()
        case 'shipments':
          return this.dataShipments()
        default:
          return []
      }
    },
    dataTableClass() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return 'consignees-data-viewer'
        case 'shippers':
          return 'shippers-data-viewer'
        default:
          return 'shipments-data-viewer'
      }
    }
  },
  methods: {
    dataConsignees() {
      return this.dataProp.map((consignee, index) => {
        consignee.index = index + 1 + (this.currentPage - 1) * this.pageLimit

        const data = this.headers.map(field => {
          const { id } = field
          let link, className, tooltip
          let value = consignee[id]

          if (typeof value !== 'number' || id === 'index') {
            if (!value) {
              value = '----'
            } else {
              switch (id) {
                case 'index':
                  link = `/company/${md5(consignee['Consignee'])}/overview`
                  break
                case 'Consignee':
                  link = `/company/${md5(value)}/overview`
                  className = `p-consignee ${
                    index === 0 ? 'first-login-tour-9' : ''
                  }`
              }
            }
          }

          const data = { text: value }
          if (link) data.link = link
          if (className) data.class = className
          if (tooltip) data.tooltip = tooltip
          return data
        })

        return { data }
      })
    },
    dataShippers() {
      return this.dataProp.map((shipper, index) => {
        shipper.index = index + 1 + (this.currentPage - 1) * this.pageLimit

        const data = this.headers.map(field => {
          const { id } = field
          let link, className, tooltip
          let value = shipper[id]

          if (typeof value !== 'number' || id === 'index') {
            if (!value && id !== 'checkbox') {
              value = '----'
            } else {
              switch (id) {
                case 'index':
                  link = `/company/${md5(shipper['Shipper'])}/overview`
                  break
                case 'Shipper':
                  link = `/company/${md5(value)}/overview`
                  className = `p-shipper ${
                    index === 0 ? 'first-login-tour-9' : ''
                  }`
              }
            }
          }

          const data = { text: value }
          if (link) data.link = link
          if (className) data.class = className
          if (tooltip) data.tooltip = tooltip
          return data
        })

        return { data }
      })
    },
    dataShipments() {
      return this.dataProp.map((shipment, index) => {
        const shipmentLink = `/${this.country}/${this.tradeType}/shipment/${
          shipment.ShipmentId
        }`
        shipment.index = index + 1 + this.pageLimit * (this.currentPage - 1)
        const isChecked = false

        const data = this.headers.map(field => {
          const { id } = field
          let link, className, tooltip, tdClassName
          let value = shipment[id]

          let billOfLading = null
          if (typeof value !== 'number' || id === 'index') {
            if (!value) {
              value = numericalFields.includes(id) ? 0 : '----'
            } else {
              switch (id) {
                case 'index':
                  link = shipmentLink
                  break
                case 'ProductDescription':
                  tdClassName = 'prod-desc'
                  break
                case 'BillOfLading':
                  const masterShipmentLink = `/${this.country}/${
                    this.tradeType
                  }/shipment/${shipment['MasterBillOfLading']}`
                  billOfLading = {
                    link: shipmentLink,
                    text: value,
                    billTypeCode: shipment['BillTypeCode'],
                    masterBillOfLadingLink: shipment['MasterBillOfLading']
                      ? masterShipmentLink
                      : null,
                    masterBillOfLading: shipment['MasterBillOfLading']
                  }
                  link = shipmentLink
                  className = `p-desc ${
                    index === 0 ? 'first-login-tour-8' : ''
                  }`
                  break
                case 'Consignee':
                case 'Shipper':
                case 'Supplier':
                  link = `/company/${shipment[id + 'Id']}/overview`
                  tdClassName = 'company-name'
                  className = `p-${id.toLowerCase()} ${
                    index === 0 ? 'first-login-tour-9' : ''
                  }`
                // case 'lastAddress':
                //   tooltip = value.fullAddress
                //   value = value.shortAddress || 'City/State Unavailable'
              }
            }
          }

          if (shipment.Highlight && shipment.Highlight[id]) {
            value = shipment.Highlight[id][0]
          }

          const cell = { text: value }
          if (link) cell.link = link
          if (className) cell.class = className
          if (tdClassName) cell.tdClass = tdClassName
          if (tooltip) cell.tooltip = tooltip
          if (billOfLading) cell.billOfLadingObject = billOfLading
          return cell
        })

        return { link: shipmentLink, isChecked: isChecked, data }
      })
    },

    makeHeaderFromField(id) {
      const { displayName: text, searchable, sortable } = this.fields[id]
      return {
        id,
        text,
        searchable,
        sortable: this.sortable ? sortable : false
      }
    },
    viewModal(open) {
      this.$emit('view-modal', open)
    }
  }
}
</script>
