<template>

  <!-- This component is currently not used and is outdated. -->

  <div
    v-if="refinements.length"
    :class="{ 'active': FirstLoginTour.currentStep === 6 && FirstLoginTour.show }"
    class="refinements-list filter-row  filter-row-3 first-login-tour-6">
    <h3>Refined By</h3>
    <div class="accordion panel-group">
      <div
        v-for="refinedByCategory in refinedByCategories"
        :key="refinedByCategory"
        class="subcategory">
        <span class="subcategory-header">{{ refinedByCategory }}</span>
        <el-collapse
          :value="activeRefinements"
          class="plain">
          <el-collapse-item
            v-for="{ field, category, refinements } in refinedByFields"
            v-if="
              fields[field] &&
                getFilteredRefinements(refinements).length &&
                category === refinedByCategory
            "
            :key="field"
            :name="field"
            class="panel panel-default">
            <div
              slot="title"
              class="panel-heading"
              role="tab">
              <h4 class="panel-title">
                {{ fields[field].displayName }}
              </h4>
            </div>
            <div
              :class="{ in: isRefinedByFieldActive(field) }"
              class="panel-collapse"
              role="tabpanel">
              <div class="panel-body">
                <refinement-list
                  :field="field"
                  :refinements="getInitialRefinements(field, refinements)"/>
                <div
                  v-if="
                    getShowMoreRefinements(field, refinements).length ||
                      isRefinedByFieldActive(field)
                  "
                  class="sub-group-button">
                  <show-more
                    v-if="getShowMoreRefinements(field, refinements).length"
                    :data="getShowMoreRefinements(field, refinements)"
                    :field="field"
                    class="refined-show-more"
                    @close="setPopoverActive(false)"
                    @open="setPopoverActive(true)">
                    <template
                      slot="list"
                      slot-scope="data">
                      <refinement-list
                        :field="field"
                        :refinements="data.items"/>
                    </template>
                  </show-more>
                  <button
                    v-if="isRefinedByFieldActive(field)"
                    type="button"
                    class="btn btn-text secondary"
                    @click.prevent="onClearRefinedByField(field)">
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { uniq, flatMap } from 'lodash'
import ShowMore from '~/components/common/ShowMore'
import RefinementList from './RefinementList'

export default {
  components: {
    ShowMore,
    RefinementList
  },
  data() {
    return {
      togglers: {}
    }
  },
  computed: {
    ...mapState('search', {
      refinedBy: state => state.refinedBy,
      refinedByFields: state => state.refinedByFields
    }),
    ...mapState('tours', {
      FirstLoginTour: state => state.FirstLoginTour
    }),
    ...mapState({
      fields: state => state.views.fields
    }),
    refinedByCategories() {
      return uniq(this.refinedByFields.map(e => e.category))
    },
    refinements() {
      return flatMap(this.refinedByFields, e => e.refinements)
    },
    activeRefinements() {
      return flatMap(this.refinedBy, e => e.field)
    }
  },
  methods: {
    ...mapMutations('search', ['clearRefinedByField']),
    ...mapActions('search', [
      'fetchShipmentsAndShipmentAggregates',
      'fetchSearchHistory',
      'fetchRefinedBy',
      'fetchShipmentGraph',
      'resetCurrentPageOfAllViews'
    ]),
    ...mapActions('analytics', ['fetchAnalyticsData']),
    ...mapActions(['setPopoverActive']),
    isRefinedByFieldActive(field) {
      return this.refinedBy.find(r => r.field.indexOf(field) > -1)
    },
    getInitialRefinements(field, refinements) {
      return [
        ...refinements.slice(0, 5),
        ...refinements
          .slice(5)
          .filter(s =>
            this.refinedBy.find(
              r =>
                r.field === field &&
                r.name.toLowerCase() === s.name.toLowerCase()
            )
          )
      ]
    },
    getShowMoreRefinements(field, refinements) {
      return refinements
        .slice(5)
        .filter(
          s =>
            !this.refinedBy.find(
              r =>
                r.field === field &&
                r.name.toLowerCase() === s.name.toLowerCase()
            )
        )
    },
    getFilteredRefinements(refinements) {
      return refinements.filter(s => s.name)
    },
    onClearRefinedByField(field) {
      this.clearRefinedByField(field)
      this.fetchRefinedBy()
      this.resetCurrentPageOfAllViews()
      this.fetchShipmentGraph()
      this.fetchAnalyticsData()
      this.fetchShipmentsAndShipmentAggregates().then(() => {
        this.fetchSearchHistory()
      })
    }
  }
}
</script>
<style src="~/assets/scss/sections/sidebar/refinements-panel.scss" lang="scss"/>
