<template>
  <el-popover
    :disabled="!buttonDisabled"
    placement="bottom"
    trigger="hover">
    <div class="popover-tooltip-notification small-content">
      <h3 v-if="hasPremiumSubscription">Search Limit Reached</h3>
      <h3 v-else>Upgrade Account</h3>
      <div class="flex-container">
        <i class="database-upgrade-icon" />
        <p v-if="searchCredits === 0 ">
          You've hit your limit of {{ searches.limit }} daily searches.
          <span v-if="!hasPremiumSubscription"><a
            href="#"
            class="upgrade-account inline-normal"
            @click.prevent="openUpgradeModal">Upgrade</a> your account to get more searches per day.</span>
        </p>
        <p v-else-if="fieldError">
          Search field is not included in your plan type.
          <a
            href="#"
            class="upgrade-account inline-normal"
            @click.prevent="openUpgradeModal">Upgrade</a> your account to view this data.
        </p>
      </div>
    </div>
    <button
      slot="reference"
      :class="{
        'btn-disable': buttonDisabled
      }"
      class="btn btn-default btn-orange search-cta"
      @click.prevent="clickHandler"><i class="search-icon white"/>
      <span>Search</span>
    </button>
  </el-popover>
</template>


<script>
import { mapGetters, mapState } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'SuggestionButton',
  mixins: [openUpgradeModal],
  props: {
    fieldError: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState('userCredits', ['searches']),
    ...mapGetters('userCredits', ['searchCredits']),
    ...mapGetters('userSubscriptions', ['hasPremiumSubscription']),
    buttonDisabled() {
      if (this.searchCredits === 0 || this.fieldError) return true
      return false
    }
  },
  methods: {
    clickHandler() {
      if (this.buttonDisabled) return

      this.$emit('search')
    }
  }
}
</script>
