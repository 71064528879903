<template>
  <div class="fieldset-group">
    <div class="input-group">
      <div 
        :class="{ 'range-filter': isRange }" 
        class="flex-container">
        <div class="fieldset">
          <fields-dropdown
            ref="fieldsDropdown"
            v-model="selectedField"
            :class="{ error: fieldError }"
            @input="handleFieldChange($event)"
            @open="$emit('focus', true)"
            @close="$emit('focus', false)" />
        </div>

        <template v-if="isRange">
          <div class="fieldset">
            <input
              v-mask="'#####'"
              ref="rangeStartInput"
              :placeholder="advancedSearchText ? advancedSearchText.startPlaceholder : null"
              :class="{ error: inputErrors.invalidRangeStart || inputErrors.empty }"
              v-model="rangeStartValue"
              type="text"
              class="keyword-input range-input"
              @input="$bus.$emit('condition-changed')"
              @keyup.enter="$emit('input-enter')"
              @focus="$emit('focus', true)"
              @blur="$emit('focus', false)" >
          </div>
          <div class="from-to">
            to
          </div>
          <div class="fieldset">
            <input
              v-mask="'#####'"
              ref="rangeEndInput"
              :placeholder="advancedSearchText ? advancedSearchText.endPlaceholder : null"
              :class="{ error: inputErrors.invalidRangeEnd || inputErrors.empty }"
              v-model="rangeEndValue"
              type="text"
              class="keyword-input range-input"
              @input="$bus.$emit('condition-changed')"
              @keyup.enter="$emit('input-enter')"
              @focus="$emit('focus', true)"
              @blur="$emit('focus', false)" >
          </div>
        </template>
        <template v-else>
          <div class="fieldset">
            <multiselect-dropdown
              v-show="isMultiselect"
              ref="keywordsDropdown"
              :class="{ error: inputErrors.empty }"
              :options="options"
              :taggable="false"
              :hide-options-when-empty="true"
              :user-input="keyword"
              :clear-input-on-select="false"
              :placeholder-text="advancedSearchText ? advancedSearchText.placeholder : null"
              :show-pointer="showPointer"
              :block-keys="blockKeys"
              @show-pointer="showPointer = $event"
              @block-keys="blockKeys = $event"
              @input-enter="$emit('input-enter')"
              @input="handleMultipleKeywords"
              @keyword-change="inputChange"
              @open="$emit('focus', true)"
              @close="$emit('focus', false)" />
            <input
              v-show="!isMultiselect"
              ref="keywordsInput"
              :placeholder="advancedSearchText ? advancedSearchText.placeholder : null"
              :class="{ error: inputErrors.empty }"
              v-model="keyword"
              type="text"
              class="keyword-input"
              @input="$bus.$emit('condition-changed')"
              @keyup.enter="$emit('input-enter')"
              @focus="$emit('focus', true)"
              @blur="$emit('focus', false)" >
          </div>

          <div class="fieldset">
            <search-type-dropdown
              ref="searchTypeDropdown"
              v-model="selectedSearchType"
              :excluded-options="excludedOptions"
              :class="{ error: typeError }"
              @input="$bus.$emit('condition-changed')"
              @open="$emit('focus', true)"
              @close="$emit('focus', false)" />
          </div>
        </template>
      </div>

      <div class="help-text">
        {{ advancedSearchText ? advancedSearchText.text : '' }}
      </div>
    </div>

    <i
      class="minus-icon"
      @mouseover="$emit('warning', !firstCondition)"
      @mouseleave="$emit('warning', false)"
      @click="$emit('remove')" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { debounce } from 'lodash'
import { advancedSearchHelpText } from '~/utils/advancedSearch'
import { statesOfAmerica, countries } from '~/utils/address'
import FieldsDropdown from '~/components/layouts/AdvancedSearchModal/FieldsDropdown'
import SearchTypeDropdown from '~/components/layouts/AdvancedSearchModal/SearchTypeDropdown'
import MultiselectDropdown from '~/components/common/MultiselectDropdown'
import { SearchTypes } from '~/utils/constants/search'
import { isEmpty } from 'lodash'

export default {
  name: 'Condition',
  components: {
    FieldsDropdown,
    MultiselectDropdown,
    SearchTypeDropdown
  },

  props: {
    firstCondition: {
      type: Boolean,
      default: false
    },
    searchCondition: {
      type: String,
      required: true
    },
    defaultField: {
      type: String,
      default: 'Consignee'
    },

    inputFieldsShown: {
      type: Boolean,
      default: false
    },

    defaultSearchType: {
      type: String,
      default: 'contains'
    }
  },

  data() {
    return {
      selectedField: this.defaultField,
      selectedSearchType: this.defaultSearchType,
      keyword: '',
      fieldError: false,
      typeError: false,
      showPointer: false,
      blockKeys: ['Enter'],

      suggestions: [],
      suggestionFields: ['Shipper', 'Consignee', 'ProductDescription'],
      definedFields: [
        'countryoforigin',
        'consigneestate',
        'carrierstate',
        'vesselcountry'
      ],

      rangeStartValue: '',
      rangeEndValue: '',
      inputErrors: {}
    }
  },

  computed: {
    ...mapState('views', ['fields']),
    ...mapState('search', {
      suggestedKeywords: state => state.keywords
    }),

    advancedSearchText() {
      return advancedSearchHelpText.find(
        condition => condition.field === this.selectedField
      )
    },

    hasDynamicOptions() {
      return this.suggestionFields.includes(this.selectedField)
    },

    hasStaticOptions() {
      return this.definedFields.includes(this.selectedField.toLowerCase())
    },

    isMultiselect() {
      return this.hasDynamicOptions || this.hasStaticOptions
    },

    options() {
      if (this.hasDynamicOptions && this.keyword) {
        return this.suggestions
      }

      switch (this.selectedField.toLowerCase()) {
        case 'vesselcountry':
        case 'countryoforigin':
          return countries
        case 'consigneestate':
        case 'carrierstate':
          return statesOfAmerica
        default:
          return []
      }
    },

    keywords() {
      return this.$refs.keywordsDropdown.getValues()
    },

    excludedOptions() {
      if (this.selectedField === 'AllFields') {
        return ['starts_with', 'ends_with']
      }

      return []
    },

    isRange() {
      return this.selectedSearchType === SearchTypes.RANGE
    }
  },

  methods: {
    ...mapActions('search', ['prefetchKeywords']),

    setValue(value) {
      let { keyword, type, field } = value
      this.clearAllInput()

      /*
        backwards compatibility for saved search, search history, and bookmarks
        since regular and exact search type are deprecated
      */
      if (type === 'exact') {
        type = 'exact_phrase'
      }

      if (type === 'regular') {
        type = 'contains'
      }

      this.selectedField = field
      this.selectedSearchType = type

      if (!keyword || !keyword.length) {
        return
      }

      if (this.isMultiselect) {
        const selected = keyword
          .map(item => this.getOptionObject(item))
          .filter(item => item && !!item.code)

        if (selected) {
          this.$emit('multiselect', selected.length)
          this.$refs.keywordsDropdown.setValue(selected)
        }

        return
      } else if (this.isRange) {
        const [start, end] = keyword[0].split('-')
        this.rangeStartValue = start
        this.rangeEndValue = end
      }

      this.keyword = keyword[0]
    },

    validate({ updateState = true } = {}) {
      this.inputErrors = {}
      if (this.isRange) {
        const trimmedStart = this.rangeStartValue.trim()
        const trimmedEnd = this.rangeEndValue.trim()

        if (!trimmedStart && !trimmedEnd) {
          this.inputErrors.empty = true
        }

        if (!trimmedStart || trimmedStart.length < 5) {
          this.inputErrors.invalidRangeStart = true
        }

        if (!trimmedEnd || trimmedEnd.length < 5) {
          this.inputErrors.invalidRangeEnd = true
        }
      } else if (this.isMultiselect) {
        if (!this.keywords.length && !this.keyword.trim()) {
          this.inputErrors.empty = true
        }
      } else {
        if (!this.keyword.trim()) {
          this.inputErrors.empty = true
        }
      }

      this.fieldError = !this.validateSearchField()

      this.typeError = !this.validateSearchType()

      const inputErr = !isEmpty(this.inputErrors)
      const err = inputErr || this.fieldError || this.typeError

      if (!updateState) {
        this.inputErrors = {}
        this.$emit('invalid-input', false)

        return !err
      }

      this.$emit('invalid-input', err)

      if (err) {
        let focusOnRange = null
        if (this.inputErrors.invalidRangeStart) {
          focusOnRange = 'start'
        } else if (this.inputErrors.invalidRangeEnd) {
          focusOnRange = 'end'
        }
        this.focus(inputErr, focusOnRange)
      }

      return !err
    },

    validateSearchField() {
      const field = this.fields[this.selectedField]
      return field && field.searchable
    },

    validateSearchType() {
      return !this.excludedOptions.includes(this.selectedSearchType)
    },

    getValue(isFinal = false) {
      if (this.isMultiselect && this.keywords.length) {
        let allkeywords = {
          keyword: Object.assign([], this.keywords),
          type: this.selectedSearchType,
          field: this.selectedField
        }

        if (isFinal && this.keyword) {
          allkeywords.keyword.push(this.keyword)
        }

        return [allkeywords]
      } else if (this.isRange) {
        if (this.rangeStartValue.trim() && this.rangeEndValue.trim()) {
          const rangeStart = +this.rangeStartValue.trim()
          const rangeEnd = +this.rangeEndValue.trim()

          this.keyword =
            rangeStart > rangeEnd
              ? `${rangeEnd}-${rangeStart}`
              : `${rangeStart}-${rangeEnd}`
        } else {
          this.keyword = ''
        }
      }
      if (!this.keyword.trim().length) return

      return [
        {
          keyword: [this.keyword],
          type: this.selectedSearchType,
          field: this.selectedField
        }
      ]
    },

    clearAllInput(clearKeyword = true) {
      this.inputErrors = {}
      this.fieldError = false
      this.$emit('multiselect', false)
      this.$emit('invalid-input', false)
      if (this.$refs.keywordsDropdown) {
        this.$refs.keywordsDropdown.setValue([])
      }
      if (!clearKeyword)
        this.$nextTick(() => {
          if (this.$refs.keywordsDropdown) {
            this.$refs.keywordsDropdown.setInputValue(this.keyword)
          }
        })
      this.clearKeyword(clearKeyword)
    },

    clearKeyword(clearKeyword = true) {
      if (clearKeyword) this.keyword = ''
      this.suggestions = []
      this.rangeEndValue = ''
      this.rangeStartValue = ''
      this.$bus.$emit('condition-changed', false)
    },

    handleMultipleKeywords(keywords) {
      this.$emit('multiselect', keywords.length)
      this.$bus.$emit(
        'condition-changed',
        !(keywords.length + this.keyword.length)
      )
    },

    inputChange(value) {
      this.keyword = value

      if (value) {
        this.fetchKeywordsSuggestions()
        this.$bus.$emit('condition-changed')
        return
      }

      this.clearKeyword()
    },

    fetchKeywordsSuggestions: debounce(
      async function fetchKeywordsSuggestions() {
        this.suggestions = [
          { name: this.keyword, code: this.keyword, isInput: true }
        ]

        if (
          !this.keyword ||
          !this.suggestionFields.includes(this.selectedField)
        ) {
          return
        }

        let field =
          this.selectedField === 'ProductDescription'
            ? 'ProductKeywords'
            : this.selectedField

        await this.prefetchKeywords({
          keyword: this.keyword,
          fields: [field]
        })

        if (this.suggestedKeywords[field]) {
          this.suggestions = this.suggestedKeywords[field].map(item => {
            return (
              item.keyword !== this.keyword && {
                name: item.keyword,
                code: item.keyword
              }
            )
          })

          this.suggestions.unshift({
            name: this.keyword,
            code: this.keyword,
            isInput: true
          })
        }
      },
      300
    ),

    getOptionObject(keyword) {
      let option =
        this.hasStaticOptions &&
        this.options.find(option => option.name === keyword)

      if (!option) {
        option = {
          name: keyword,
          code: keyword
        }
      }

      return option
    },

    focus(focusInput = true, focusOnRange = null) {
      if (focusInput) {
        if (this.isMultiselect) {
          return this.$nextTick(() => this.$refs.keywordsDropdown.$el.focus())
        } else if (focusOnRange === 'start') {
          return this.$nextTick(() => this.$refs.rangeStartInput.focus())
        } else if (focusOnRange === 'end') {
          return this.$nextTick(() => this.$refs.rangeEndInput.focus())
        } else {
          return this.$nextTick(() => this.$refs.keywordsInput.focus())
        }
      }

      this.$nextTick(() => this.$refs.fieldsDropdown.$el.focus())
    },

    handleFieldChange(field) {
      const previousSearchType = this.selectedSearchType
      if (this.fields[field] && this.fields[field].defaultSearchType) {
        this.selectedSearchType = this.fields[field].defaultSearchType
      } else if (previousSearchType === SearchTypes.RANGE) {
        // since range is not a selectable search type, reset search type to default
        this.selectedSearchType = this.defaultSearchType
      }

      const shouldClearKeyword =
        previousSearchType === SearchTypes.RANGE ||
        this.selectedSearchType === SearchTypes.RANGE

      this.clearAllInput(shouldClearKeyword)
    },

    isInputEmpty() {
      if (this.isMultiselect) {
        return !this.keywords.length && !this.keyword.trim()
      } else if (this.isRange) {
        return !this.rangeStartValue.trim() && !this.rangeEndValue.trim()
      } else {
        return !this.keyword.trim()
      }
    }
  }
}
</script>
