<template>
  <multiselect
    v-model="selectedTradeType"
    :options="tradeTypes"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    label="label"
    track-by="label"
    class="custom-select"
    @open="$emit('toggle', true)"
    @close="$emit('toggle', false)">
    <template
      slot="singleLabel"
      slot-scope="props">
      <span class="trade-type">
        {{ props.option.label || props.option }} Data
      </span>
    </template>
    <template
      slot="option"
      slot-scope="props">
      <i class="fa fa-info-circle"/> <span class="coming-soon">Export Data still in progress</span>
      <!-- @TODO - uncomment when ready -->
      <!-- <span class="trade-type"> {{ props.option.label }} Data </span> -->
    </template>
  </multiselect>
</template>

<script>
export default {
  name: 'TradeTypesDropdown',
  computed: {
    selectedTradeType: {
      get() {
        return this.$store.state.tradeType
      },
      set(tradeType) {
        this.$store.dispatch('setTradeType', tradeType.label)
      }
    },
    tradeTypes() {
      return this.$store.state.tradeTypes
    }
  }
}
</script>
