<template>
  <div
    v-loading="$actions.pending('search/fetchSearchHistory')"
    class="history-section">
    <h3 @click="$emit('show-items')">
      <i class="clock-icon"/>
      <span>History</span>
    </h3>
    <div class="overflow-scroll">
      <search-history-items
        v-if="searchHistory.length"
        :class="{ 'no-items': searchHistory.length < 1 }"
        :data="searchHistory"
        @search="handleSearch"/>
      <div
        v-else
        class="no-record-found"
        @click.stop="$emit('switch', 'new')">
        <p>
          You have no searches yet. Make a
          <strong>search</strong> so you can view it here.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SearchHistoryItems from './SearchHistoryItems'

export default {
  components: {
    SearchHistoryItems
  },
  computed: {
    ...mapState({
      searchHistory: state => state.search.searchHistory
    })
  },
  methods: {
    ...mapActions('search', ['searchFromCriteria']),
    handleSearch({ criteria }) {
      this.searchFromCriteria({ criteria })
      this.$emit('switch', 'new')
    }
  }
}
</script>
