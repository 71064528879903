<template>
  <el-popover
    ref="popover"
    v-model="isOpen"
    popper-class="with-footer savesearch"
    @show="focusInput"
    @hide="clearErrors">
    <div class="popover-content">
      <div class="pop-over-default">
        <h2>Save Search</h2>
        <fieldset>
          <h3>Name</h3>
          <el-tooltip
            :value="!!errorMessage.length && isOpen"
            :manual="true"
            placement="bottom"
            popper-class="is-light dr-tooltip-error">
            <template slot="content">
              <div class="popover-tooltip-notification">
                <h3>Error</h3>
                <div class="flex-container">
                  <i class="error-icon" />
                  <p>{{ errorMessage }}</p>
                </div>
              </div>
            </template>
            <input
              ref="input"
              v-model="name"
              :class="{ error: errorMessage }"
              type="text"
              @keyup.enter="save"
              @keyup.escape="name = ''">
          </el-tooltip>
        </fieldset>
      </div>
    </div>
    <div class="popover-footer flex-container">
      <button
        class="btn-default btn-text flex-items"
        @click="save">
        Save
      </button>
    </div>
    <button
      slot="reference"
      class="btn btn-text">
      Save
    </button>
  </el-popover>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SaveSearchPopover',
  data() {
    return {
      isOpen: false,
      name: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapState('search', ['savedSearches'])
  },
  methods: {
    ...mapActions('search', ['addSavedSearch']),
    focusInput() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
      if (!this.name.length) {
        this.name = `search-${new Date().getTime()}`
      }
    },
    save() {
      this.name = this.name.trim()
      if (!this.name) {
        this.errorMessage = 'Name cannot be blank.'
      }
      if (this.name.length > 24) {
        this.errorMessage = 'Name is too long.'
      }
      if (this.errorMessage) {
        setTimeout(() => {
          this.errorMessage = ''
        }, 4000)
        return
      }

      this.addSavedSearch(this.name)
      this.$emit('switch', 'saved')

      this.name = ''
      this.$refs.popover.doClose()
    },
    clearErrors() {
      this.errorMessage = ''
    }
  }
}
</script>
