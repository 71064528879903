<template>
  <div
    :class="{
      'full-screen': inFullscreen,
      'height-100': (dataTableTotal && dataTableTotal < 5),
      'padding-b-0': !totalShipments,
      'zero-results': !totalShipments
    }"
    class="card-base">
    <search-criteria-bar
      :fields="fields"
      :search-credits="searchCredits"/>

    <data-viewer-header
      :show-full-screen-icon="false"
      @view-modal="setCustomViewOpen">
      <template
        slot="headerLeft">
        <aggregate-header-tabs/>
      </template>

      <template #headerRight="{}">

        <shipment-alert-dialog
          v-show="hasCurrentSearch && showShipmentAlertButton"
          :user-email="user.email"/>

        <download-results
          v-if="!isAggregateViewAnalytics"
          :disabled="disableDownload"
          :result-limit="maxDownloadLimit"
          :type="downloadType"/>

        <analytics-downloader
          v-else
          :disabled="disableDownload"
          :chart-type="chartType" />

        <bookmark-popover
          :is-disabled="isAggregateViewAnalytics"
          :has-data="activeTotal"
          :disabled="isAggregateViewAnalytics"/>

        <el-tooltip
          v-if="showFullScreenIcon"
          :content="inFullscreen ? 'Exit Fullscreen' : 'Fullscreen'">
          <i
            :class="{
              'minimize-window-icon': inFullscreen,
              'full-window-icon': !inFullscreen
            }"
            @click="setInFullscreen(!inFullscreen)"/>
        </el-tooltip>
      </template>
    </data-viewer-header>

    <overview v-if="isAggregateViewOverview && hasData" />

    <search-selected-data-viewer
      v-if="!isAggregateViewAnalytics && !isAggregateViewOverview"
      ref="searchSelectedDataViewer"
      :loading="loading"
      :data-prop="dataViewTable"
      :date-range-text="dateRangeText"
      :limit="dataTableLimit"
      :total="dataTableTotal"
      :sort-field="dataTableSortField"
      :sort-order="dataTableSortOrder"
      :page-limit="dataTablePageLimit"
      :current-page="dataTableCurrentPage"
      :active-aggregate-view="activeAggregateView"
      :has-checkboxes="true"
      :show-custom-view="false"
      :show-cards="true"
      :show-cards-icon="false"
      :show-full-screen-icon="false"
      :floating-scrollbar-options="floatingScrollbarOptions"
      @sort="dataTableSortShipmentsAndClearCheckboxes"
      @limit="dataTableSetPageLimit"
      @page="dataTableChangeShipmentsCurrentPage"
      @rows-selected="dataTableSetSelectedRow" />

    <analytics v-if="isAggregateViewAnalytics && hasData" />

    <criteria-suggestion
      v-if="!hasData"
      :data="dataViewTable"/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import DataViewerHeader from '~/components/data-viewers/DataViewer/DataViewerHeader/DataViewerHeader'
import AggregateHeaderTabs from './DataViewer/DataViewerHeader/AggregateHeaderTabs'
import Overview from '~/components/data-viewers/OverviewDataViewer'
import DownloadResults from '~/components/data-viewers/DataViewer/DataViewerHeader/DownloadResults'
import ShipmentAlertDialog from '~/components/data-viewers/DataViewer/DataViewerHeader/ShipmentAlertDialog'
import BookmarkPopover from '~/components/data-viewers/DataViewer/DataViewerHeader/BookmarkPopover'
import SearchCriteriaBar from '~/components/search/SearchCriteriaBar'
import Analytics from '../../pages/index/analytics'
import AnalyticsDownloader from '~/components/analytics/AnalyticsOptionsToolbar/AnalyticsDownloader'
import SearchSelectedDataViewer from './SearchSelectedDataViewer'
import CriteriaSuggestion from '~/components/data-viewers/DataViewer/CriteriaSuggestion'

export default {
  name: 'AggregateDataViewer',
  components: {
    SearchSelectedDataViewer,
    Overview,
    Analytics,
    DataViewerHeader,
    AggregateHeaderTabs,
    DownloadResults,
    BookmarkPopover,
    SearchCriteriaBar,
    AnalyticsDownloader,
    CriteriaSuggestion,
    ShipmentAlertDialog
  },
  data() {
    return {
      showFullScreenIcon: true
    }
  },
  computed: {
    ...mapState('analytics', ['chartType']),
    ...mapState('tours', ['FirstLoginTour']),
    ...mapState(['inFullscreen']),
    ...mapState('views', ['activeAggregateView', 'fields']),
    ...mapState('accounts', ['user']),
    ...mapState('search', [
      'shipments',
      'totalShipments',
      'currentPage',
      'pageLimit',
      'sortField',
      'sortOrder'
    ]),
    ...mapState('search/aggregatedConsignees', {
      consigneeSortField: state => state.sortField,
      consigneeSortOrder: state => state.sortOrder,
      consigneeCurrentPage: state => state.currentPage,
      consigneePageLimit: state => state.pageLimit,
      totalConsignees: state => state.totalConsignees,
      aggregateConsigneesLimit: state => state.aggregateLimit
    }),
    ...mapState('search/aggregatedShippers', {
      shipperSortField: state => state.sortField,
      shipperSortOrder: state => state.sortOrder,
      shipperCurrentPage: state => state.currentPage,
      shipperPageLimit: state => state.pageLimit,
      totalShippers: state => state.totalShippers,
      aggregateShippersLimit: state => state.aggregateLimit
    }),
    ...mapState('dataViewer', ['viewType']),
    ...mapGetters('userCredits', ['searchCredits']),
    ...mapGetters('search', ['dateRangeText']),
    ...mapGetters('search', [
      'hasSearch',
      'searchKeywords',
      'hasCurrentSearch'
    ]),

    ...mapGetters('search/aggregatedConsignees', ['aggregatedConsignees']),
    ...mapGetters('search/aggregatedShippers', ['aggregatedShippers']),
    hasData() {
      return this.dataViewTable.length
    },
    showShipmentAlertButton() {
      return !this.$route.path.includes('/shipment-alert')
    },
    floatingScrollbarOptions() {
      return {
        zIndex: 2,
        offsetBottom: this.inFullscreen ? 0 : 59
      }
    },

    downloadType() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return 'aggregateConsignees'
        case 'shippers':
          return 'aggregateShippers'
        default:
          return 'search'
      }
    },
    activeTotal() {
      let total = 0
      switch (this.activeAggregateView) {
        case 'consignees':
          total = this.totalConsignees
          break
        case 'shippers':
          total = this.totalShippers
          break
        default:
          total = this.totalShipments
      }

      return total || 0
    },
    disableDownload() {
      return !this.hasData || this.isAggregateViewOverview
    },
    maxDownloadLimit() {
      const max = ['consignees', 'shippers'].includes(this.activeAggregateView)
        ? 500
        : 1000
      return this.activeTotal < max ? this.activeTotal : max
    },
    isActiveAggregateViewShipment() {
      return this.activeAggregateView === 'shipments'
    },
    isAggregateViewAnalytics() {
      return this.activeAggregateView === 'analytics'
    },
    isAggregateViewOverview() {
      return this.activeAggregateView === 'overview'
    },
    dataViewTable() {
      let dataTable = []

      switch (this.activeAggregateView) {
        case 'consignees':
          dataTable = this.aggregatedConsignees
          break
        case 'shippers':
          dataTable = this.aggregatedShippers
          break
        default:
          dataTable = this.shipments
      }

      return dataTable || []
    },

    dataTableCurrentPage() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.consigneeCurrentPage
        case 'shippers':
          return this.shipperCurrentPage
        default:
          return this.currentPage
      }
    },
    dataTablePageLimit() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.consigneePageLimit
        case 'shippers':
          return this.shipperPageLimit
        default:
          return this.pageLimit
      }
    },
    dataTableSortOrder() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.consigneeSortOrder
        case 'shippers':
          return this.shipperSortOrder
        case 'shipments':
          return this.sortOrder
        default:
          return 'desc'
      }
    },
    dataTableSortField() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.consigneeSortField
        case 'shippers':
          return this.shipperSortField
        default:
          return this.sortField
      }
    },
    dataTableLimit() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.aggregateConsigneesLimit
        case 'shippers':
          return this.aggregateShippersLimit
        default:
          return 10000
      }
    },
    dataTableTotal() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.totalConsignees
        case 'shippers':
          return this.totalShippers
        default:
          return this.totalShipments
      }
    },
    loading() {
      let loading = false
      if (this.activeAggregateView === 'shipments')
        loading = loading || this.$actions.pending('search/fetchShipments')
      if (this.activeAggregateView === 'consignees')
        loading =
          loading ||
          this.$actions.pending(
            'search/aggregatedConsignees/fetchAggregatedConsignees'
          )
      if (this.activeAggregateView === 'shippers')
        loading =
          loading ||
          this.$actions.pending(
            'search/aggregatedShippers/fetchAggregateShippers'
          )
      return loading
    }
  },
  watch: {
    $route(to, from) {
      if (to.params.searchId !== from.params.searchId)
        this.clearCheckboxes([SearchSelectedDataViewer])
    }
  },
  methods: {
    ...mapActions('search', ['setSorting', 'setCurrentPage', 'setPageLimit']),
    ...mapActions('search/aggregatedConsignees', {
      setConsigneeSorting: 'setSorting',
      setConsigneePageLimit: 'setPageLimit',
      setConsigneeCurrentPage: 'setCurrentPage'
    }),
    ...mapActions('search/aggregatedShippers', {
      setShipperSorting: 'setSorting',
      setShipperPageLimit: 'setPageLimit',
      setShipperCurrentPage: 'setCurrentPage'
    }),
    ...mapMutations(['setInFullscreen']),
    ...mapMutations('search', ['setPaginationStatus', 'setCustomViewOpen']),
    ...mapMutations('dataViewer', ['setViewType']),
    ...mapMutations('downloads', ['setSelectedRows']),

    dataTableSetPageLimit(pageLimit) {
      switch (this.activeAggregateView) {
        case 'consignees':
          this.setConsigneePageLimit(pageLimit)
          break
        case 'shippers':
          this.setShipperPageLimit(pageLimit)
          break
        default:
          this.setPageLimit(pageLimit)
      }
    },
    dataTableChangeShipmentsCurrentPage(page) {
      switch (this.activeAggregateView) {
        case 'consignees':
          this.setConsigneeCurrentPage(page)
          break
        case 'shippers':
          this.setShipperCurrentPage(page)
          break
        default:
          this.changeShipmentsCurrentPage(page)
      }
    },
    changeShipmentsCurrentPage(page) {
      this.setCurrentPage(page)
    },
    clearCheckboxes(dataTableComponents = []) {
      dataTableComponents.forEach(dataTableComponent => {
        this.$bus.$emit(`clearCheckboxesOf${dataTableComponent.name}`)
      })
    },
    dataTableSortShipmentsAndClearCheckboxes($event) {
      switch (this.activeAggregateView) {
        case 'consignees':
          this.setConsigneeSorting($event)
          break
        case 'shippers':
          this.setShipperSorting($event)
          break
        default:
          this.setSorting($event)
      }
      this.clearCheckboxes([SearchSelectedDataViewer])
    },
    dataTableSetSelectedRow($event) {
      this.setSelectedRows({ view: this.activeAggregateView, rows: $event })
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/data-viewer-header.scss" lang="scss"/>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.2;
}
html.height-inherit {
  height: 100% !important;
  .search-page {
    .card-base {
      margin-bottom: 0 !important;
      height: 100%;
    }
  }
}
</style>
