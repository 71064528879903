<template>
  <el-popover
    ref="popover"
    transition="fade"
    trigger="click"
    placement="bottom"
    popper-class="show-more-popper">
    <button
      slot="reference"
      type="button" 
      class="btn btn-text">{{ text }}</button>

    <div
      class="widget-table">
      <div class="inner">
        <input
          v-model="filter"
          class="full-width min-with-inherit"
          type="text"
          placeholder="Search"
          autocomplete="off">
      </div>
      <div class="search-term-items">
        <slot 
          :items="filteredData" 
          name="list"/>
      </div>
      <span 
        class="btn btn-text secondary dm-cancel" 
        @click="hidePopover">Cancel</span>
    </div>
  </el-popover>
</template>

<script>
import { statesKeys } from '~/utils/address'

export default {
  props: {
    text: {
      type: String,
      default: 'Show More'
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    field: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filter: '',
      refsLoaded: false
    }
  },
  computed: {
    isOpen() {
      return this.refsLoaded && this.$refs.popover.show
    },
    filteredData() {
      if (this.field === 'ConsigneeState') {
        return this.data.filter(item => {
          if (statesKeys[item.name] === undefined) {
            return
          }
          return (
            statesKeys[item.name]
              .toLowerCase()
              .indexOf(this.filter.toLowerCase()) > -1
          )
        })
      }
      return this.data.filter(
        item => item.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
      )
    }
  },
  watch: {
    isOpen(val) {
      this.$emit(val ? 'open' : 'close')
    }
  },
  mounted() {
    this.refsLoaded = true
  },
  methods: {
    hidePopover() {
      if (this.refsLoaded) {
        this.$refs.popover.doClose()
      }
    }
  }
}
</script>
