<template>
  <section
    :class="{
      'z-index-2': inFullscreen,
      'nav-filter-mobile': navFilterMobile,
      'nav-filter-mobile-gotop': navFilterMobile && scrolled,
      'active': FirstLoginTour.currentStep === 6
    }"
    class="section-nav-filter">

    <ul class="search-tabs">
      <li :class="{ active: isSidebarContentOpen }">
        <button
          :disabled="isSidebarContentOpen"
          class="btn btn-default btn-orange search-cta"
          @click="showSidebarContent">
          <i class="search-icon white"/>
          <span>Search</span>
        </button>
      </li>
    </ul>

    <search-history @show-items="sidebar && $emit('show')"/>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Tab from '~/components/common/Tab'
import Tabs from '~/components/common/Tabs'
import CountriesDropdown from '~/components/common/CountriesDropdown'
import NewSearch from './NewSearch'
import SearchHistory from './SearchHistory'
import TradeTypesDropdown from './TradeTypesDropdown'

export default {
  components: {
    CountriesDropdown,
    Tab,
    Tabs,
    NewSearch,
    SearchHistory,
    TradeTypesDropdown
  },
  props: {
    sidebar: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      navFilterMobile: state => state.navFilterMobile,
      popoverActive: state => state.popoverActive,
      scrolled: state => state.scrolled,
      FirstLoginTour: state => state.tours.FirstLoginTour,
      isSidebarContentOpen: state => state.isSidebarContentOpen,
      inFullscreen: state => state.inFullscreen
    }),
    thisYear() {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions(['setCountry', 'setPopoverActive']),
    selectTab(tab = 'new') {
      this.$refs.tabs.selectTab(this.$refs[tab])
    },
    showSidebarContent() {
      if (this.sidebar) {
        this.$emit('show')
      }
      this.$bus.$emit('open-advanced-search-drawer', {
        sideBar: true
      })
    }
  }
}
</script>
<style src="~/assets/scss/sections/sidebar/sidebar.scss" lang="scss"/>
<style src="~/assets/scss/sections/sidebar/sidebar-tabs.scss" lang="scss"/>
