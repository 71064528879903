<template>
  <div class="tab with-item-count aggregate-view exp-1">
    <button
      v-for="view in aggregateViews"
      :key="view.id"
      :class="{ active: activeAggregateView === view.id }"
      @click="changeTab(view.id)">
      {{ view.name }}
      <span
        v-if="view.total" 
        class="item-count with-blue-shade">{{ getDisplay(view.id, view.total) }}</span>
    </button>
    <button
      v-if="userHasAnalyticsSubscription"
      :class="{ active: activeAggregateView === 'analytics' }"
      @click="changeTab('analytics')">
      Analytics
    </button>
    <el-popover
      v-else
      ref="analytics"
      placement="bottom-start"
      width="250"
      trigger="hover"
      class="upgrade-option"
      popper-class="upgrade-popover">
      <div class="popover-tooltip-notification">
        <h3>Upgrade</h3>
        <div class="flex-container">
          <i class="analytics-graphs-icon large-icon" />
          <p>
            <a
              href="#"
              class="inline-normal"
              @click="showUpgrade">Upgrade</a> your subscription to access the Analytics.</p>
        </div>
      </div>
      <span slot="reference">
        <button class="disabled">
          Analytics
          <span class="item-count with-blue-shade">Upgrade</span>
        </button>
      </span>
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { numberWithLetters } from '~/utils/number'
import SearchSelectedDataViewer from '../../SearchSelectedDataViewer'
export default {
  name: 'AggregateHeaderTabs',
  filters: {
    numberWithLetters
  },
  computed: {
    ...mapState('views', ['activeAggregateView']),
    ...mapState('search', ['totalShipments']),
    ...mapState('search/aggregatedConsignees', ['totalConsignees']),
    ...mapState('search/aggregatedShippers', ['totalShippers']),
    ...mapState('userSubscriptions', ['userHasAnalyticsSubscription']),
    aggregateViews() {
      return [
        {
          id: 'overview',
          name: 'Results Overview'
        },
        {
          id: 'shipments',
          name: 'Shipments',
          total: this.totalShipments
        },
        {
          id: 'consignees',
          name: 'Consignees',
          total: this.totalConsignees
        },
        {
          id: 'shippers',
          name: 'Shippers',
          total: this.totalShippers
        }
      ]
    }
  },
  methods: {
    ...mapMutations('views', ['setActiveAggregateView']),
    ...mapMutations('userSubscriptions', ['setOpenUpgradeModal']),
    changeTab(tabName) {
      this.setActiveAggregateView(tabName)
      this.$bus.$emit(`clearCheckboxesOf${SearchSelectedDataViewer.name}`)
    },
    showUpgrade() {
      this.$refs.analytics.$refs.popper.style.display = 'none'
      this.setOpenUpgradeModal(true)
    },
    getDisplay(view, total) {
      const isFetchingShipments =
        view === 'shipments' && this.$actions.pending('search/fetchShipments')
      const isFetchingConsignees =
        view === 'consignees' &&
        this.$actions.pending(
          'search/aggregatedConsignees/fetchAggregatedConsignees'
        )
      const isFetchingShippers =
        view === 'shippers' &&
        this.$actions.pending(
          'search/aggregatedShippers/fetchAggregateShippers'
        )

      if (
        !this.$actions.pending('search/fetchSearchHeaderToken') &&
        !isFetchingShipments &&
        !isFetchingConsignees &&
        !isFetchingShippers
      ) {
        return numberWithLetters(total, 2)
      }

      return '--'
    }
  }
}
</script>
