var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"base-checkbox"},[_vm._l((_vm.refinements.filter(function (refinement) { return refinement.name; })),function(refinement,index){return _c('li',{key:refinement.name,class:{
      reset: _vm.isWithinSelection(refinement) && _vm.selection.type === '',
      included: _vm.isWithinSelection(refinement) && _vm.selection.type === 'include',
      excluded: _vm.isWithinSelection(refinement) && _vm.selection.type === 'exclude'
    },attrs:{"tabindex":"0"},on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.onItemClick(refinement)},function($event){if(!$event.shiftKey){ return null; }_vm.select(index, _vm.getCondition(refinement) ? '' : 'include')}],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnter($event, refinement, index)}}},[_c('refined-by-checkbox',{attrs:{"refinement":refinement,"condition":_vm.getCondition(refinement)},on:{"toggleWithShift":function($event){return _vm.select(index, $event)},"changeCondition":function($event){return _vm.toggleRefinedBy({
          field: _vm.field,
          name: refinement.name,
          condition: $event
        })}}})],1)}),_vm._v(" "),(_vm.refinements.length === 0)?_c('li',[_vm._v("No refinements available.")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }