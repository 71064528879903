<template>
  <div class="section-filter">
    <div
      v-if="!hasSearch && !refinedBy.length && !refinedByFields.length"
      class="no-record-found">
      <div class="circle for-base-icon-bg base-red absolute-pos">
        <span class="notification-icon" />
      </div>
      <p>
        You have no searches yet. &nbsp; Make a new search to see more options.
      </p>
    </div>
    <div
      v-else
      class="filter-header">
      <div class="filter-header-buttons">
        <save-search-popover @switch="switchTab"/>
        <button
          type="button"
          class="btn btn-text secondary"
          @click="clearSearch">
          Clear
        </button>
      </div>
    </div>
    <!-- <keywords /> -->
    <refinements-panel />
    <misc-filters />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import SaveSearchPopover from './SaveSearchPopover'
// import Keywords from './Keywords'
import RefinementsPanel from './RefinementsPanel/RefinementsPanel'
import MiscFilters from './MiscFilters'

export default {
  components: {
    SaveSearchPopover,
    RefinementsPanel,
    MiscFilters
  },
  computed: {
    ...mapState('search', ['refinedBy', 'refinedByFields']),
    ...mapGetters('search', ['hasSearch'])
  },
  methods: {
    ...mapActions('search', ['clearSearch']),
    switchTab(tab) {
      this.$emit('switch', tab)
    }
  }
}
</script>
