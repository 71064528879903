<template>
  <ul v-click-outside="() => selectIndex(null)">
    <el-popover
      v-for="(row, index) in data"
      :key="index"
      :title="row.occurred_on | dateSearched"
      placement="right"
      trigger="hover">

      <search-history-popover :history="row" />

      <nuxt-link
        slot="reference"
        :class="{ selected: selectedIndex === index }"
        :to="generateLink(row.search_id)">
        <div>
          {{ row.criteria.keywords[0] && row.criteria.keywords[0].keyword || getKeyword(row.criteria.keywords.i[0]) }}
        </div>
        <span>{{ row.records_found }} results</span>
        <i
          class="edit-icon"
          @click.prevent="viewSearchCriteria(row.criteria)" />
      </nuxt-link>
    </el-popover>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { upperFirst, keyBy } from 'lodash'
import { countries } from '~/config'
import { splitAdvancedKeywords } from '~/utils/advancedSearch'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'
import SearchHistoryPopover from './SearchHistoryPopover.vue'
import $date from '~/plugins/date/methods'
import { SearchTypes } from '~/utils/constants/search'

export default {
  name: 'SearchHistoryItems',
  components: {
    SearchHistoryPopover
  },
  filters: {
    upperFirst,
    countryName(value) {
      return keyBy(countries, 'countryCode')[value].name
    },
    dateSearched(d) {
      return 'Searched: ' + $date.formatUtc(+d * 1000, 'yyyy-MM-dd')
    }
  },
  mixins: [openUpgradeModal],
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedIndex: null,
      popoverOpen: null
    }
  },
  computed: {
    ...mapGetters('userCredits', ['searchCredits'])
  },
  methods: {
    selectIndex(index) {
      this.selectedIndex = index
    },
    search(row) {
      this.$emit('search', row)
    },
    getKeyword(keyword) {
      if (!keyword) {
        return 'No Keyword'
      }
      const extractedKeyword = splitAdvancedKeywords(keyword)
      if (
        extractedKeyword.type === SearchTypes.RANGE &&
        extractedKeyword.keyword[0]
      ) {
        return extractedKeyword.keyword[0].replace('-', ' to ')
      }

      return extractedKeyword.keyword[0]
    },
    viewSearchCriteria(criteria) {
      this.$bus.$emit('view-search-criteria', criteria)
    },
    generateLink(searchId) {
      return `/search/${searchId}?view=overview`
    }
  }
}
</script>
