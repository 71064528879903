export const tipsResoure = [
  {
    title: "Finding your competitor's suppliers",
    description:
      "Use the consignee filter and your competitor name to find your competitor's shipments. Then view the suppliers tab to get a bird's eye view of all their overseas suppliers.",
    media: null,
    type: '',
    link:
      'https://blog.importgenius.com/how-to-find-your-competitors-suppliers/'
  },
  {
    title: 'Decloaking suppliers',
    description: `Sometimes importers do not use their business name to avoid exposing their supply chain. Search by <b>notify name</b> to see shipments where a consignee was listed as receiver.
      You can also try searching for the company name under <b>consignee address</b>. At times, a company will preface their address with their name. Lastly, you can try to search by the consignee's shipper. This may reveal alternate names or addresses.`,
    media: {
      main:
        'https://importgenius-public.s3.amazonaws.com/consignee%20address%20notify%20notify%20address.webm',
      fallback:
        'https://importgenius-public.s3.amazonaws.com/consignee%20address%20notify%20notify%20address.gif'
    },
    link: ''
  },
  {
    title: 'Filtering out freight forwarders',
    description:
      'To filter out results with the logistics company listed as the consignee/shipper, check the <b>House Only</b> filter next to the date range. To view only the results that include logistics company names, check the <b>Master Only</b> filter',
    media: {
      main: 'https://importgenius-public.s3.amazonaws.com/isng-house-only.webm',
      fallback:
        'https://importgenius-public.s3.amazonaws.com/isng-house-only.gif'
    },
    link: ''
  },
  {
    title: 'Combining search criteria',
    description:
      'To further narrow down your search, you can  combine multiple criteria. Simply pick from the <b>AND OR NOT</b> buttons to add another criteria. As an example this will allow you to see shipments from a consignee coming only from a particular country. You can also use this to see shipments from a supplier coming into a port or coming from a specific country',
    media: {
      main:
        'https://importgenius-public.s3.amazonaws.com/ISNG-Search-AND-Hongkong.webm',
      fallback:
        'https://importgenius-public.s3.amazonaws.com/ISNG-Search-AND-Hongkong.gif'
    },
    link: ''
  },
  {
    title: 'Talk to our trade experts',
    description:
      'Finding it difficult to find what you are looking for? Chat to our trade experts by clicking on the chat icon in the header. ',
    media: {
      main: 'https://importgenius-public.s3.amazonaws.com/isng-need-help.webm',
      fallback:
        'https://importgenius-public.s3.amazonaws.com/isng-need-help.gif'
    },
    link: ''
  }
]

export function tip() {
  const randomTip = tipsResoure[Math.floor(Math.random() * 5)]
  let link = ''
  if (randomTip.link !== '') {
    link = `<a target="_blank" class="btn btn-link" href="${
      randomTip.link
    }">Learn More</a>`
  }
  const tip = { ...randomTip, description: randomTip.description + link }
  return tip
}
