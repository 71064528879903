<template>
  <div>
    <fieldset
      class="misc">
      <div class="field-inner">
        <ul class="base-checkbox">
          <li
            v-for="miscFilter in miscFiltersToDisplay"
            :key="miscFilter.fieldName">
            <el-checkbox
              :value="selectedMiscFilters.indexOf(miscFilter.value) > -1"
              @change="
                toggleMiscFilter(miscFilter.value)
              ">
              <span class="el-checkbox__label">
                {{ miscFilter.displayName }}
                <el-tooltip
                  :content="miscFilter.info"
                  placement="top"
                  effect="light">
                  <i>(?)</i>
                </el-tooltip>
              </span>
            </el-checkbox>
          </li>
        </ul>
      </div>
    </fieldset>

    <fieldset class="stats-daterange">
      <div class="field-inner">
        <div
          v-loading="isPrefetchingCount"
          class="stats-preview">
          <h3>Estimated Results</h3>
          <div
            class="stat-container">
            <div
              v-for="count in estimateResultsCount"
              :key="count.label"
              class="single-stat">
              <div class="stat-count">
                <span>{{ count.value }}</span>
                <span>
                  {{ count.suffix }}
                </span>
              </div>
              <h4>{{ count.label | toUpper }}</h4>
            </div>
          </div>
        </div>

        <div class="search-daterange">
          <h3>Date Range</h3>
          <date-range-selector
            :date-ranges="dateRangePresets"
            :selected-range="selectedDateRange"
            :min-date="minDate"
            :max-date="maxDate"
            :is-date-picker-visible="isDatePickerVisible"
            align="right"
            class="date-picker el-input el-input--prefix el-date-editor--daterange"
            @daterange="changeDateRange"
            @validate-date-range="validateDateRangeMisc"
            @showed-date-picker="setIsDatePickerVisible" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { toUpper, filter } from 'lodash'
import { miscFilters } from '~/config'
import { numberWithLetters } from '~/utils/number'
import TimePeriod from '~/utils/TimePeriod'
import DateRangeSelector from '~/components/common/DateRangeSelector'

export default {
  name: 'Miscellaneous',
  components: {
    DateRangeSelector
  },

  filters: {
    toUpper,
    numberWithLetters
  },

  props: {
    preFetchedCounts: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isValidateDateRange: true,
      selectedMiscFilters: [],
      selectedDateRange: { from: null, to: null }
    }
  },

  computed: {
    ...mapState(['minDate', 'maxDate']),
    ...mapState('search', ['isDatePickerVisible']),
    isPrefetchingCount() {
      if (this.isValidateDateRange) {
        return this.$actions.pending('search/preFetchResultCount')
      }
    },

    isDropdown() {
      return this.options && this.options.dropdown
    },

    dateRangePresets() {
      const allPresetMinDate = TimePeriod.subtractDateRangeFromTimestamp(
        this.maxDate,
        '5 years'
      )
      return [
        ...this.$date.getCommonDateRanges(this.maxDate),
        { label: 'ALL', text: 'ALL', from: allPresetMinDate, to: this.maxDate }
      ]
    },

    miscFiltersToDisplay() {
      return miscFilters
    },

    estimateResultsCount() {
      const { shipments, aggregates } = this.preFetchedCounts
      let estimateCount = []

      estimateCount.push({
        label: 'shipments',
        ...numberWithLetters(shipments ? shipments.dateRange : 0, 2, true)
      })
      estimateCount.push({
        label: 'consignees',
        ...numberWithLetters(
          aggregates ? aggregates.Consignee.dateRange : 0,
          2,
          true
        )
      })
      estimateCount.push({
        label: 'shippers',
        ...numberWithLetters(
          aggregates ? aggregates.Shipper.dateRange : 0,
          2,
          true
        )
      })

      return estimateCount
    }
  },

  methods: {
    ...mapMutations('search', ['setIsDatePickerVisible']),
    validateDateRangeMisc(isValidateDateRange) {
      this.isValidateDateRange = isValidateDateRange
      this.$emit('validate-date-range', isValidateDateRange)
    },

    setMiscFilters(value) {
      this.selectedMiscFilters = value
    },

    getMiscFilters() {
      return this.selectedMiscFilters
    },

    toggleMiscFilter(field) {
      const miscIndex = this.selectedMiscFilters.indexOf(field)
      const specialFilters = [
        'MasterShipmentsExcluded',
        'HouseShipmentsOnly',
        'MasterShipmentsOnly'
      ]

      if (miscIndex < 0) {
        if (specialFilters.indexOf(field) > -1) {
          this.selectedMiscFilters = filter(
            this.selectedMiscFilters,
            m => specialFilters.indexOf(m) === -1
          )
        }

        this.selectedMiscFilters.push(field)
      } else {
        this.selectedMiscFilters.splice(miscIndex, 1)
      }

      this.$emit('condition-changed')
    },

    setDateRange(value) {
      this.selectedDateRange = {
        from: value.from,
        to: value.to
      }
    },

    getDateRange() {
      if (this.selectedDateRange.from && this.selectedDateRange.to)
        return this.selectedDateRange
      return null
    },

    changeDateRange(dateRange) {
      const { from, to } = dateRange
      dateRange.from = this.$date.getUtcDate(from).getTime()
      dateRange.to = this.$date.getUtcDate(to).getTime()

      if (
        JSON.stringify(this.selectedDateRange) !== JSON.stringify(dateRange)
      ) {
        this.selectedDateRange = dateRange
        this.$emit('condition-changed')
      }
    },

    format(value) {
      const numberSplit = value.toString().split('.')
      const decimalValue = numberSplit[1] || ''
      const decimals = decimalValue.length >= 2 ? 2 : decimalValue.length

      return value.toLocaleString('en', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
    }
  }
}
</script>
