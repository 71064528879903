<template>

  <!-- This component is currently not used and is outdated. -->

  <div
    v-if="miscFiltersToDisplay.length"
    class="subcategory">
    <span class="subcategory-header">Miscellaneous</span>
    <ul class="base-checkbox">
      <li
        v-for="miscFilter in miscFiltersToDisplay"
        :key="miscFilter.field">
        <label
          class="control control-checkbox"
          tabindex="0"
          @keydown.enter="toggleMiscFilter(miscFilterConfig(miscFilter.field).value, miscFilterExists(miscFilter.field))">
          {{ miscFilterConfig(miscFilter.field).displayName }}
          <input
            :id="miscFilter.field"
            :checked="
              miscFilters.indexOf(miscFilterConfig(miscFilter.field).value) > -1
            "
            tabindex="-1"
            type="checkbox"
            @change="
              toggleMiscFilter(miscFilterConfig(miscFilter.field).value, miscFilterExists(miscFilter.field))
            ">
          <span class="control-indicator" />
          <span class="item-count">
            {{ miscFilter.totalCount | numberWithLetters(1) }}
          </span>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { miscFilters } from '~/config'
import { numberWithLetters } from '~/utils/number'

export default {
  filters: {
    numberWithLetters
  },
  computed: {
    ...mapState('search', {
      miscFilters: state => state.miscFilters,
      availableMiscFilters: state => state.availableMiscFilters
    }),
    miscFiltersToDisplay() {
      return this.availableMiscFilters.filter(
        misc =>
          misc.totalCount > 0 ||
          misc.field === 'BlankConsigneesExcluded' ||
          misc.field === 'BlankShippersExcluded'
      )
    }
  },
  methods: {
    ...mapMutations('search', [
      'setCurrentPage',
      'addMiscFilter',
      'removeMiscFilter'
    ]),
    ...mapActions('search', [
      'fetchShipmentsAndShipmentAggregates',
      'fetchSearchHistory',
      'fetchRefinedBy',
      'fetchShipmentGraph'
    ]),
    ...mapActions('analytics', ['fetchAnalyticsData']),
    miscFilterExists(field) {
      return this.miscFilters.indexOf(this.miscFilterConfig(field).value) < 0
    },
    toggleMiscFilter(field, val) {
      if (val) {
        this.addMiscFilter(field)
      } else {
        this.removeMiscFilter(field)
      }

      this.fetchRefinedBy()

      this.setCurrentPage(1)
      this.fetchShipmentGraph()
      this.fetchAnalyticsData()
      this.fetchShipmentsAndShipmentAggregates().then(() => {
        this.fetchSearchHistory()
      })
    },
    miscFilterConfig(val) {
      return miscFilters[miscFilters.map(m => m.value).indexOf(val)]
    }
  }
}
</script>
