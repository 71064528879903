<template>
  <div>
    <div
      v-if="showCriteriaSuggestion"
      class="zero-search-options">
      <div
        class="alert alert-warning with-icon">
        <i class="alert-icon"/>
        Your search has no results. Try the following to expand your search.
      </div>
      <div class="zero-search-option-body">
        <div class="search-option-group">
          <ul>
            <li v-if="showDateCriteria && !hasAccessToAllData">
              <div class="option-item">
                <p>Search from <strong>{{ $date.format(subscriptionDateRange.from, 'yyyy-MM-dd') }} - {{ $date.format(subscriptionDateRange.to, 'yyyy-MM-dd') }}</strong>.</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.date.subscription | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchDateCriteria()"/>
            </li>

            <li v-if="conditionCriteria">
              <div class="option-item">
                <p>Search for <strong>"{{ allKeywords[0] }}" OR "{{ allKeywords[1] }}"</strong> instead</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.condition.OR | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchConditionCriteria"/>
            </li>

            <li v-if="longestWordCriteria">
              <div class="option-item">
                <p>Search only for <strong>"{{ longestWordCriteria }}"</strong></p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.keyword[0].count | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchWordCriteria"/>
            </li>

            <li v-if="showMiscCriteria({misc: 'MasterShipmentsOnly', prop: 'uncheckMasterOnly'})">
              <div class="option-item">
                <p>Include non <strong>Master Bills</strong> of lading in your search</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.misc.uncheckMasterOnly | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchMiscCriteria('MasterShipmentsOnly')"/>
            </li>

            <li v-if="showMiscCriteria({misc: 'HouseShipmentsOnly', prop: 'uncheckHouseOnly'})">
              <div class="option-item">
                <p>Include non <strong>House Bills</strong> of lading in your search</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.misc.uncheckHouseOnly | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchMiscCriteria('HouseShipmentsOnly')"/>
            </li>

            <li v-if="showMiscCriteria({misc: 'BlankConsigneesExcluded', prop: 'includeBlankConsignee'})">
              <div class="option-item">
                <p>Include shipments with empty declared consignees</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.misc.includeBlankConsignee | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchMiscCriteria('BlankConsigneesExcluded')"/>
            </li>

            <li v-if="showAllFieldCriteria && !hasPlanLimited">
              <div class="option-item">
                <p>Search for <strong>"{{ allKeywords[0] }}"</strong> in All Fields.</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.fields.AllFields | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchAllFieldCriteria"/>
            </li>

            <li v-if="showAllDataCriteria && hasAccessToAllData">
              <div class="option-item">
                <p>Search from <strong>{{ $date.format(dateLimits.from, 'yyyy-MM-dd') }} onwards</strong>.</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.date.allData | formatShipmentCount }}</span>shipments.</p>
              </div>
              <suggestion-button
                :field-error="fieldError"
                @search="searchAllDataCriteria"/>
            </li>
          </ul>
          <div class="search-remaining">You have <strong>{{ searchCredits }} remaining search credit{{ searchCredits > 1 ? 's' : '' }}</strong>.
          </div>
        </div>

        <div
          v-if="showUpgradeSuggestions"
          class="search-option-group">
          <h2>You can try the following searches when you upgrade your ImportGenius subscription</h2>
          <ul>
            <li v-if="showAllDataCriteria && !hasAccessToAllData">
              <div class="option-item">
                <p>Search from <strong>{{ $date.format(dateLimits.from, 'yyyy-MM-dd') }} onwards</strong>.</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.date.allData | formatShipmentCount }}</span>shipments.</p>
              </div>
              <button
                class="btn btn-default btn-primary"
                @click.prevent="openUpgradeModal">Upgrade</button>
            </li>

            <li v-if="showAllFieldCriteria && hasPlanLimited">
              <div class="option-item">
                <p>Search for <strong>"{{ allKeywords[0] }}"</strong> in All Fields.</p>
                <p>Will find<span class="item-count with-blue-shade">{{ criteriaSuggestions.fields.AllFields | formatShipmentCount }}</span>shipments.</p>
              </div>
              <button
                class="btn btn-default btn-primary"
                @click.prevent="openUpgradeModal">Upgrade</button>
            </li>
          </ul>
        </div>

      </div>
    </div>

    <div
      v-if="data.length === 0 && !showCriteriaSuggestion"
      class="alert alert-warning with-icon">
      No data found
    </div>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'
import SuggestionButton from './SuggestionButton'
import { cloneDeep } from 'lodash'

export default {
  name: 'CriteriaSuggestion',
  components: {
    SuggestionButton
  },
  filters: {
    formatShipmentCount(val) {
      return val > 100 ? '100+' : val
    }
  },
  mixins: [openUpgradeModal],
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      keywords: []
    }
  },
  computed: {
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('userCredits', ['searchCredits']),
    ...mapGetters('search', [
      'criteriaSuggestions',
      'searchKeywords',
      'searchCriteria',
      'hasSearch'
    ]),
    ...mapGetters('views', ['availableFields']),
    ...mapGetters('userSubscriptions', [
      'hasAccessToAllData',
      'subscriptionDateRange',
      'hasPremiumSubscription',
      'hasPlanLimited',
      'isAdmin'
    ]),
    ...mapGetters(['arrivalDateBetween', 'dateLimits']),
    showUpgradeSuggestions() {
      const showUpgradeAllField =
        this.showAllFieldCriteria && this.hasPlanLimited
      const showUpgradeAllData =
        this.showAllDataCriteria && !this.hasAccessToAllData

      if (this.hasPremiumSubscription || this.isAdmin) return false
      if (!(showUpgradeAllField || showUpgradeAllData)) return false

      return true
    },
    showDateCriteria() {
      const { from, to } = this.arrivalDateBetween
      const {
        from: subscriptionFrom,
        to: subscriptionTo
      } = this.subscriptionDateRange
      const criteria = {
        criteria: 'date',
        prop: 'subscription'
      }

      if (!this.checkCriteriaProperty(criteria)) return false
      return from > subscriptionFrom / 1000 || to < subscriptionTo / 1000
    },
    showAllFieldCriteria() {
      const criteria = {
        criteria: 'fields',
        prop: 'AllFields'
      }

      if (this.searchKeywords.i.length !== 1) return false
      if (this.searchKeywords.i[0].field === 'AllFields') return false

      return this.checkCriteriaProperty(criteria)
    },
    showAllDataCriteria() {
      const criteria = { criteria: 'date', prop: 'allData' }

      return this.checkCriteriaProperty(criteria)
    },
    conditionCriteria() {
      const criteria = { criteria: 'condition', prop: 'OR' }

      if (this.searchKeywords.i.length !== 2) return false
      if (this.searchKeywords.i[1].o !== 'AND') return false

      return this.checkCriteriaProperty(criteria)
    },
    longestWordCriteria() {
      if (typeof this.criteriaSuggestions.keyword === 'undefined') return false
      if (this.criteriaSuggestions.keyword[0].count === 0) return false

      return this.criteriaSuggestions.keyword[0].keyword
    },
    miscCriteria() {
      const { misc } = this.searchCriteria()
      return misc
    },
    allKeywords() {
      const searchKeywords = this.searchKeywords.i.map(({ i }) => {
        return i[0].keyword.join(', ')
      })

      return searchKeywords
    },
    fieldError() {
      if (this.hasKeywords) {
        this.getSearchCriteria()
      }

      return this.keywords.some(
        element => typeof this.availableFields[element.field] === 'undefined'
      )
    },
    showCriteriaSuggestion() {
      const miscsCriteria = [
        {
          misc: 'MasterShipmentsOnly',
          prop: 'uncheckMasterOnly'
        },
        {
          misc: 'HouseShipmentsOnly',
          prop: 'uncheckHouseOnly'
        },
        {
          misc: 'BlankConsigneesExcluded',
          prop: 'includeBlankConsignee'
        }
      ]
      if (this.data.length === 0 && this.hasSearch) {
        if (this.showDateCriteria) return true
        if (this.showAllFieldCriteria) return true
        if (this.showAllDataCriteria) return true
        if (this.conditionCriteria) return true
        if (this.longestWordCriteria) return true
        if (miscsCriteria.some(this.showMiscCriteria)) return true
      }
      return false
    },
    hasKeywords() {
      return Object.keys(this.searchKeywords).length > 0
    }
  },
  methods: {
    ...mapActions('search', ['setDateRange', 'search']),
    ...mapMutations('search', ['setSearchKeywords', 'setMiscFilters']),
    getSearchCriteria() {
      this.keywords = []
      this.identifiers = this.getGroupCriteria(cloneDeep(this.searchKeywords))
      return this.keywords
    },
    getGroupCriteria(condition) {
      const keywordsArray = condition.i
      const type = 'condition'

      const ids = keywordsArray.map(condition => {
        if (condition.i) {
          return this.getGroupCriteria(condition)
        } else {
          return this.getKeyword(condition)
        }
      })

      return { type, ids }
    },
    getKeyword(condition) {
      const identifier = {
        type: 'keyword',
        id: condition.id,
        field: condition.field,
        keyword: condition.keyword
      }

      this.keywords.push(identifier)
      return identifier
    },
    checkCriteriaProperty({ criteria, prop }) {
      if (typeof this.criteriaSuggestions[criteria] === 'undefined')
        return false
      if (!this.criteriaSuggestions[criteria][prop]) return false

      return true
    },
    showMiscCriteria({ misc, prop }) {
      if (!this.miscCriteria.includes(misc)) return false

      return this.checkCriteriaProperty({ criteria: 'misc', prop })
    },
    updateFirstSearchKeywords(fieldObject) {
      return {
        ...this.searchKeywords,
        i: [
          {
            ...this.searchKeywords.i[0],
            i: [
              {
                ...this.searchKeywords.i[0].i[0],
                ...fieldObject
              }
            ]
          }
        ]
      }
    },
    searchDateCriteria() {
      this.setDateRange(this.subscriptionDateRange)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    searchAllDataCriteria() {
      this.setDateRange(this.dateLimits)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    searchAllFieldCriteria() {
      const shallowSearchKeywords = this.updateFirstSearchKeywords({
        field: 'AllFields'
      })

      this.setSearchKeywords(shallowSearchKeywords)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    searchConditionCriteria() {
      const shallowSearchKeywords = {
        ...this.searchKeywords,
        i: [
          {
            i: [...this.searchKeywords.i[0].i, ...this.searchKeywords.i[1].i],
            o: 'OR'
          }
        ]
      }

      this.setSearchKeywords(shallowSearchKeywords)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    searchWordCriteria() {
      const shallowSearchKeywords = this.updateFirstSearchKeywords({
        keyword: [this.longestWordCriteria]
      })
      this.setSearchKeywords(shallowSearchKeywords)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    },
    searchMiscCriteria(filterCondition) {
      const miscFilters = this.miscCriteria.filter(
        misc => misc !== filterCondition
      )
      this.setMiscFilters(miscFilters)
      this.search().then(() => this.$router.push(this.currentSearchURL))
    }
  }
}
</script>
