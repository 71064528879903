<template>
  <div class="search-drawer">
    <div class="card-base quick-search">
      <div
        v-if="currentSearchId"
        class="stripe-button"
        @click="setSidebarContent(false)" />
      <h2>Search US Import Data</h2>
      <div
        ref="advancedFilter"
        :style="advancedFilterStyle"
        class="advanced-filter">
        <template v-for="(condition, index) in conditions">
          <condition-group
            :ref="`condition${index}`"
            :key="condition.id"
            :default-field="defaultCondition.i[0].field"
            :first-condition="!index"
            @hide-tips="tipVisibility = false"
            @addcondition="addCondition"
            @remove="remove(index)"
            @search="searchAll"/>
        </template>
      </div>

      <div
        v-if="tipVisibility && wasOpen && !tipClosed"
        class="tips-and-tricks">
        <div class="inner-content">
          <i
            class="removed-icon"
            @click="tipClosed = true"/>
          <h2>{{ tip.title }}</h2>
          <p v-html="tip.description"/>
          <div class="image-video-container">
            <video
              v-if="tip.media !== null"
              :key="tip.media.main"
              width="250"
              height="140"
              autoplay
              loop>
              <source
                :src="tip.media.main"
                type="video/webm">

              <img
                :src="tip.media.fallback"
                alt="fallback image">
            </video>

            <el-checkbox @change="toggleTipsVisibility">
              Dont show tips
            </el-checkbox>
          </div>
        </div>
      </div>

      <div
        :class="{ 'with-shadow': isOverflow }"
        class="search-controls">
        <miscellaneous
          ref="miscellaneous"
          :pre-fetched-counts="preFetchedCounts"
          @condition-changed="prefetchResultCount"
          @validate-date-range="setValidateDateRange"/>

        <fieldset class="helptext-button">
          <div class="field-inner">
            <div class="remaining-searches">
              <p>
                You {{ searches.count ? 'only' : '' }} have
                <strong>{{ searchCredits }} remaining search{{ searchCredits > 1 ? 'es' : '' }}</strong>.
                <span v-if="!hasPremiumSubscription && !isAdmin">
                  <a
                    href="#"
                    class="alert-link"
                    @click.prevent="openUpgradeModal">Upgrade now</a>
                  to get more searches.
                </span>
              </p>
            </div>
            <div class="cta-buttons">
              <button
                class="btn btn-text secondary"
                type="button"
                @click.prevent="clear">Clear</button>
              <button
                :disabled="!valid"
                :class="{ disabled: !valid }"
                class="btn btn-default btn-orange"
                type="button"
                @click.prevent="searchAll">
                <i class="search-icon white" />
                <span>Search</span>
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { debounce, cloneDeep } from 'lodash'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'
import { updateKeywordStructure } from '~/utils/advancedSearch'
import { tip } from '~/utils/tips'
import ConditionGroup from '~/components/layouts/Sidebar/AdvancedSearchDrawer/ConditionGroup'
import Miscellaneous from '~/components/layouts/Sidebar/AdvancedSearchDrawer/Miscellaneous'
import uuid from 'uuid/v4'
export default {
  name: 'AdvancedSearchDrawer',
  components: {
    ConditionGroup,
    Miscellaneous
  },

  mixins: [openUpgradeModal],
  data() {
    return {
      conditions: [
        {
          type: 'condition',
          id: uuid()
        }
      ],
      keywordIdList: [],
      isWarning: false,
      viewingSearch: false,
      newSearch: false,
      sideBar: false,
      isOverflow: false,
      isValidInput: false,
      isValidDateRange: true,
      isCriteriaDateRange: false,
      criteriaDateRange: null,
      tip: tip(),
      tipVisibility: true,
      tipClosed: false,
      wasOpen: false,
      defaultConditionKey: ''
    }
  },

  computed: {
    ...mapState([
      'osVersion',
      'isSidebarContentOpen',
      'dateRange',
      'minDate',
      'maxDate'
    ]),
    ...mapState('search', [
      'preFetchedCounts',
      'miscFilters',
      'currentSearchId',
      'isDatePickerVisible'
    ]),
    ...mapState('userCredits', ['searches', 'searchCredits']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('search', [
      'hasSearch',
      'defaultAllowedMiscFilters',
      'searchKeywords'
    ]),
    ...mapGetters('userSubscriptions', [
      'hasPlanLimited',
      'subscriptionDateRange',
      'hasPremiumSubscription',
      'isAdmin'
    ]),
    ...mapGetters('userCredits', ['searchCredits']),
    advancedFilterStyle() {
      let overflow = 'auto'
      if (this.osVersion === 'Windows') overflow = 'scroll'

      return {
        overflow
      }
    },

    estimateCount() {
      const { shipments } = this.preFetchedCounts
      return shipments ? shipments.dateRange : 0
    },
    defaultCondition() {
      return {
        o: '',
        i: [
          {
            keyword: [''],
            type: 'contains',
            field: this.defaultConditionKey,
            id: 'default'
          }
        ]
      }
    },

    advancedSearchKeywords() {
      return this.hasSearch ? this.searchKeywords : this.defaultCondition
    },

    searchableDateRange() {
      const { from, to } = this.subscriptionDateRange
      return {
        from: from < this.minDate ? this.minDate : from,
        to: to > this.maxDate ? this.maxDate : to
      }
    },

    valid() {
      return this.isValidInput && this.isValidDateRange && this.searchCredits
    }
  },

  watch: {
    isSidebarContentOpen(isOpen) {
      if (!isOpen) {
        this.viewingSearch = false
        this.newSearch = false
        this.wasOpen = false
        this.tipClosed = false
        this.closeDatePicker()
        this.criteriaDateRange = null
        return
      }

      window.scroll({
        top: 0,
        left: 0
      })

      this.isCriteriaDateRange = false

      if (this.newSearch) {
        this.setValue(this.defaultCondition)
        this.setMiscellaneous(
          this.defaultAllowedMiscFilters,
          this.searchableDateRange
        )
        this.criteriaDateRange = this.searchableDateRange
      } else if (
        !this.viewingSearch &&
        JSON.stringify(this.getValue()) !==
          JSON.stringify(this.advancedSearchKeywords)
      ) {
        this.setValue(this.advancedSearchKeywords)
        this.setMiscellaneous(
          this.hasSearch ? this.miscFilters : this.defaultAllowedMiscFilters,
          this.criteriaDateRange
        )

        this.criteriaDateRange = null
        return
      }

      this.criteriaDateRange = null
    },

    conditions(newValue) {
      this.tipVisibility =
        !this.$cookies.get('tips-hidden') && newValue.length < 3

      if (this.tipVisibility && !this.wasOpen) this.tip = tip()

      this.wasOpen = this.isSidebarContentOpen
    }
  },

  created() {
    this.$bus.$on('condition-changed', this.prefetchResultCount)
    this.$bus.$on('view-search-criteria', this.viewSearchCriteria)
    this.$bus.$on('open-advanced-search-drawer', this.openDrawer)
    this.$bus.$on('open-date-picker', this.openDatePicker)
    this.attachDrawerClosable()
  },

  destroyed() {
    this.$bus.$off('condition-changed', this.prefetchResultCount)
    this.$bus.$off('view-search-criteria', this.viewSearchCriteria)
    this.$bus.$off('open-advanced-search-drawer', this.openDrawer)
    this.$bus.$off('open-date-picker', this.openDatePicker)
    this.removeDrawerClosable()
  },

  mounted() {
    this.setDefaultConditionKey()
    this.setValue(this.advancedSearchKeywords)

    if (this.hasSearch) {
      this.setMiscellaneous(this.miscFilters, this.dateRange)
      return
    }

    this.setMiscellaneous(
      this.defaultAllowedMiscFilters,
      this.searchableDateRange
    )
  },

  methods: {
    ...mapGetters('views', ['availableFields']),
    ...mapMutations(['setSidebarContent']),
    ...mapMutations('search', [
      'clearPreFetchedResultCounts',
      'setPreFetchSearchKeywords',
      'setSearchKeywords',
      'setMiscFilters',
      'setIsDatePickerVisible'
    ]),
    ...mapActions('search', [
      'preFetchResultCount',
      'setDateRange',
      'clearSearch',
      'search'
    ]),

    handleKeydown(event) {
      if (event.key === 'Escape') {
        if (!this.isDatePickerVisible && this.hasSearch)
          this.setSidebarContent(false)
        event.stopPropagation()
      }
    },

    attachDrawerClosable() {
      document.body.addEventListener('keydown', this.handleKeydown)
    },

    removeDrawerClosable() {
      document.body.removeEventListener('keydown', this.handleKeydown)
    },

    openDrawer(options = {}) {
      const {
        newSearch = !this.hasSearch,
        keywordId = null,
        focusInput = true,
        sideBar = false
      } = options
      this.sideBar = sideBar
      this.newSearch = newSearch
      this.setSidebarContent(true)
      this.$bus.$emit('show', true)

      if (focusInput) this.$nextTick(() => this.focusOnInput(keywordId))
    },

    toggleTipsVisibility(hidden) {
      this.$cookies.set('tips-hidden', `${hidden}`)
    },

    openDatePicker() {
      setTimeout(() => {
        this.setIsDatePickerVisible(true)
      }, 5)
    },

    closeDatePicker() {
      this.setIsDatePickerVisible(false)
    },

    focusOnInput(keywordId = null) {
      const index = this.findElementOfKeyword(keywordId)
      this.$nextTick(() => this.$refs[`condition${index}`][0].focus())
    },

    findElementOfKeyword(keywordId) {
      const index = this.keywordIdList.indexOf(keywordId)

      if (index < 0) return this.keywordIdList.length - 2
      return index
    },

    setValue(keywords) {
      if (!keywords.i) return

      this.conditions = []
      this.keywordIdList = []

      this.setConditions(keywords)

      // for the last condition
      this.addCondition(false)
      this.$nextTick(this.checkIfOverflow)
    },

    setConditions(keyword, cond) {
      if (keyword.keyword) {
        this.addCondition(false, keyword.id)

        const conditionIndex = this.conditions.length - 1
        this.$nextTick(() => {
          if (this.$refs[`condition${conditionIndex}`][0])
            this.$refs[`condition${conditionIndex}`][0].setValue(
              Object.assign(
                {},
                {
                  o: cond,
                  i: [keyword]
                }
              )
            )
        })

        return
      }

      keyword.i.forEach((item, index) => {
        /**
         * when displaying keywords on the search drawer for condition groups that contain multiple conditions,
         * that is, they are using the OR search condition, use the search condition of that condition group
         * for the first condition, instead of its own search condition which is OR
         */
        const o = keyword.i.length > 1 && !index && cond ? cond : keyword.o
        this.setConditions(item, o)
      })
    },

    getValue(option) {
      // check if there are any conditions
      const conditions = Object.keys(this.$refs).filter(
        key => key.replace(/[0-9]/g, '') === 'condition'
      )

      if (!conditions.length) return

      return {
        o: 'AND',
        i: this.arrangeSearchGroupPrecedence(
          this.conditions
            .map((c, index) => {
              if (this.$refs[`condition${index}`][0]) {
                const condition = this.$refs[`condition${index}`][0].getValue(
                  option
                )
                if (!condition) return ''

                return condition
              }

              return ''
            })
            .filter(item => !!item.o)
        )
      }
    },

    searchAll() {
      const isValid = this.validate({ updateState: true }) && this.valid

      if (!isValid) return
      if (!this.isValidDateRange) return

      const advancedSearchKeywords = this.getValue(true)

      this.setSearchKeywords(advancedSearchKeywords)
      this.setMiscFilters(cloneDeep(this.$refs.miscellaneous.getMiscFilters()))
      this.setDateRange(cloneDeep(this.$refs.miscellaneous.getDateRange()))

      this.setSidebarContent(false)

      this.search().then(() => this.$router.push(this.currentSearchURL))
      this.viewingSearch = false
    },

    validate(options) {
      // If all fields are empty, validate all empty fields to update errors
      // If at least one field has an input, ignore empty fields and validate non-empty fields to check for errors
      // Last condition group always has no fields
      if (!this.conditions || this.conditions.length <= 1) {
        return false
      }

      let conditionIdsToValidate = this.getNonEmptyConditions()
      if (conditionIdsToValidate.length === 0) {
        conditionIdsToValidate = this.conditions.map((c, index) => {
          return `condition${index}`
        })
      }

      let conditionsValid = true
      conditionIdsToValidate.forEach(conditionId => {
        try {
          const isConditionValid = this.$refs[conditionId][0].validate(options)
          if (!isConditionValid) {
            conditionsValid = false
          }
        } catch (e) {
          conditionsValid = false
        }
      })

      this.isValidInput = conditionsValid
      return this.isValidInput && this.valid
    },

    getNonEmptyConditions() {
      if (!this.conditions || this.conditions.length <= 1) return []

      return this.conditions
        .map((c, index) => {
          return `condition${index}`
        })
        .filter(conditionId => {
          try {
            return !this.$refs[conditionId][0].isInputEmpty()
          } catch (err) {
            return false
          }
        })
    },

    prefetchResultCount: debounce(function prefetchResultCount(
      clearSearch = false
    ) {
      let isValid = this.validate({ updateState: false }) && this.valid

      if (!isValid || clearSearch) {
        this.clearPreFetchedResultCounts()

        return
      }

      if (!this.isValidDateRange) {
        this.clearPreFetchedResultCounts()
        return
      }

      const advancedSearchKeywords = this.getValue(true)

      this.setPreFetchSearchKeywords(advancedSearchKeywords)

      const options = this.$refs.miscellaneous
        ? {
            misc: this.$refs.miscellaneous.getMiscFilters(),
            dateRange: this.$refs.miscellaneous.getDateRange()
          }
        : { misc: [], dateRange: '0-0' }

      this.preFetchResultCount(options).then(() => {
        isValid = this.validate({ updateState: false }) && this.valid
        if (!isValid) this.clearPreFetchedResultCounts()
      })
    },
    500),

    addCondition(checkOverflow = true, keywordId = 'default') {
      this.conditions.push({
        type: 'condition',
        id: uuid()
      })

      this.keywordIdList.push(keywordId)

      if (checkOverflow) this.checkIfOverflow()
    },

    remove(index) {
      const recomputeResultCount = this.$refs[`condition${index}`][0].validate({
        updateState: false
      })

      this.conditions.splice(index, 1)

      if (this.conditions.length < 1) {
        this.clear()
        this.isOverflow = false
        return
      }

      this.checkIfOverflow()
      if (recomputeResultCount) {
        this.prefetchResultCount()
      }
    },

    clear() {
      this.prefetchResultCount(true)
      this.setValue(this.defaultCondition)
      this.$refs.miscellaneous.setDateRange(this.searchableDateRange)
      this.focusOnInput()
    },

    viewSearchCriteria(criteria) {
      this.setSidebarContent(true)

      const { keywords, misc = [], arrivalDates } = criteria
      this.viewingSearch = true
      this.newSearch = false
      this.setValue(updateKeywordStructure(keywords))
      let arrivalDateBetween = null

      if (arrivalDates) {
        const parsedDates = arrivalDates.split('-')
        const [from, to] = parsedDates
        arrivalDateBetween = {
          from: +from * 1000,
          to: +to * 1000
        }

        this.isCriteriaDateRange = true
        this.criteriaDateRange = arrivalDateBetween
      }

      this.setMiscellaneous(misc, arrivalDateBetween)

      this.focusOnInput()
    },

    setValidateDateRange(isValidate) {
      this.isValidDateRange = isValidate
    },

    arrangeSearchGroupPrecedence(conditions) {
      let arrangedKeywords = []

      conditions.map(item => {
        if (arrangedKeywords.length && item.o === 'OR') {
          const lastIndex = arrangedKeywords.length - 1

          if (arrangedKeywords[lastIndex].o === 'OR') {
            arrangedKeywords[lastIndex].i.push(...item.i)
            return
          }

          if (arrangedKeywords[lastIndex].i[0].o === 'OR') {
            arrangedKeywords[lastIndex].i[0].i.push(...item.i)
            return
          }

          arrangedKeywords[lastIndex].i = Object.assign(
            [],
            [
              {
                o: 'OR',
                i: [...arrangedKeywords[lastIndex].i, ...item.i]
              }
            ]
          )

          return
        }

        arrangedKeywords.push(item)
      })

      return arrangedKeywords
    },

    setMiscellaneous(misc, dateRange = null) {
      this.$refs.miscellaneous.setMiscFilters(cloneDeep(misc))
      this.$refs.miscellaneous.setDateRange(
        cloneDeep(dateRange || this.dateRange)
      )
    },

    checkIfOverflow() {
      this.$nextTick(() => {
        const element = this.$refs.advancedFilter

        if (element && element.scrollHeight > element.clientHeight) {
          this.isOverflow = true
          return
        }

        this.isOverflow = false
      })
    },

    setDefaultConditionKey() {
      this.defaultConditionKey = this.availableFields()
        ? Object.keys(this.availableFields())[0]
        : ''
    }
  }
}
</script>
