<template>

  <div
    class="criteria-section search-history-popover">
    <ul>
      <li
        :class="{ error: errorDateRange }"
        class="card-base">
        <h4>Date Range</h4>
        <div
          class="criteria-section">
          <ul class="criteria-group">
            <li
              class="criteria-pill">
              {{ history.criteria.arrivalDates | formatRange }}
            </li>
          </ul>
        </div>
      </li>
      <li
        v-for="(field, index) in keywords"
        :key="index"
        :class="{ error: field.error }"
        class="card-base">
        <h4>{{ fields[field.field].displayName }}</h4>
        <div
          class="criteria-section">
          <ul class="criteria-group">
            <li
              v-for="(keyword, k) in field.keyword"
              :key="k"
              class="criteria-pill">
              {{ keyword | formatKeyword(field.type) }}
            </li>
          </ul>
        </div>
      </li>
      <li
        v-if="history.criteria.misc && history.criteria.misc.length"
        class="card-base">
        <h4>Filters</h4>
        <div class="criteria-section">
          <ul class="criteria-group">
            <li
              v-for="(filter, i) in history.criteria.misc"
              :key="i"
              class="criteria-pill">
              {{ filter | formatFilter }}
            </li>
          </ul>
        </div>
      </li>
    </ul>

  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex'
import $date from '~/plugins/date/methods'
import { miscFilters } from '~/config/config'
import { updateKeywordStructure } from '~/utils/advancedSearch'

export default {
  filters: {
    formatRange(d) {
      const parseDates = d.split('-')
      const [from, to] = parseDates
      return (
        $date.formatUtc(+from * 1000, 'yyyy-MM-dd') +
        '-' +
        $date.formatUtc(+to * 1000, 'yyyy-MM-dd')
      )
    },
    formatFilter(f) {
      let n = f
      miscFilters.forEach(m => {
        if (m.value == n) n = m.displayName
      })
      return n
    }
  },
  props: {
    history: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      keywords: [],
      fil: miscFilters
    }
  },
  computed: {
    ...mapState('views', ['fields']),
    ...mapGetters('userSubscriptions', ['subscriptionDateRange']),

    // Returns true if the search date range of the search history item is invalid
    errorDateRange() {
      const { from, to } = this.subscriptionDateRange
      const arrivalDates = this.history.criteria.arrivalDates.split('-')
      const hFrom = parseInt(arrivalDates[0]) * 1000
      const hTo = parseInt(arrivalDates[1]) * 1000

      return hFrom < from || hTo > to
    }
  },
  watch: {
    history() {
      this.keywords = []
      this.generateKeywords()
    }
  },
  mounted() {
    this.generateKeywords()
  },
  methods: {
    /**
     * Generate and format the keywords object to be displayed on the popover
     *
     * Note: If this code gets/is repeated, refactor to a utility module
     */
    generateKeywords(w) {
      if (!w) {
        w = updateKeywordStructure(this.history.criteria.keywords)
      }

      Object.keys(w).forEach(k => {
        if (k == 'i') {
          w[k].forEach(v => {
            if (v.field) {
              this.keywords.push({
                ...v,
                error: !this.validateSearchField(v.field)
              })
            } else this.generateKeywords(v)
          })
        }
      })
    },
    /**
     * Returns true if the search field used in a search condition is valid
     */
    validateSearchField(searchField) {
      const field = this.fields[searchField]
      return field && field.searchable
    }
  }
}
</script>

<style src="~/assets/scss/sections/sidebar/search-history-popover.scss" lang="scss"/>
