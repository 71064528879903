<template>
  <label
    :class="{
      included: condition === 'include',
      excluded: condition === 'exclude'
    }"
    class="refined-by-checkbox"
    @mouseenter="showPopover = true"
    @mouseleave="handleMouseLeave">

    <el-popover
      ref="popover"
      :disabled="$device.isMobile"
      :value="showPopover"
      :popper-class="popperRefinement"
      trigger="manual"
      placement="right">

      <span
        slot="reference"
        :class="{
          included: condition === 'include',
          excluded: condition === 'exclude'
        }"
        class="control-indicator"/>

      <div
        :class="{ 'arrow-blue': condition === 'include' }"
        class="refined-checkbox__options"
        @mouseleave="handleMouseLeave">
        <i
          :class="{ selected: condition === 'include' }"
          class="checkmark-icon-default blue-color"
          @click.shift="
            $emit('toggleWithShift', condition === 'include' ? '' : 'include')
          "
          @click.exact.stop="
            $emit('changeCondition', condition === 'include' ? '' : 'include')
          "/>
        <span class="horizontal-divider"/>
        <i
          :class="{ selected: condition === 'exclude' }"
          class="exclude-icon-default red-color"
          @click.shift="
            $emit('toggleWithShift', condition === 'exclude' ? '' : 'exclude')
          "
          @click.exact.stop="
            $emit('changeCondition', condition === 'exclude' ? '' : 'exclude')
          "/>
      </div>
    </el-popover>

    <span class="refined-terms">{{ refinement.name | filterRefinementName }}</span>
    <span class="item-count">{{ refinement.count | numberWithLetters(1) }}</span>
  </label>
</template>

<script>
import { numberWithLetters } from '~/utils/number'
import { statesKeys } from '~/utils/address'
import dom from '~/utils/dom'

export default {
  filters: {
    filterRefinementName(val) {
      return statesKeys[val] || val
    },
    numberWithLetters
  },
  props: {
    refinement: {
      type: Object,
      required: true
    },
    condition: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPopover: false
    }
  },
  computed: {
    popperRefinement() {
      return this.condition === 'include'
        ? 'refined-checkbox-popover blue-popper-arrow'
        : 'refined-checkbox-popover'
    }
  },
  methods: {
    handleMouseLeave({ target, x, y }) {
      this.showPopover = dom.getIsInBounds(target, x, y)
    }
  }
}
</script>
<style lang="scss">
.refined-checkbox-popover {
  width: 50px;
  min-width: inherit;
  height: 22px;
  /*margin: 0;*/
  padding: 0;
  line-height: 22px;
  background: $brand-white-color;
  box-shadow: 0 0 4px 0 rgba(52, 64, 80, 0.2);
  border-radius: 2px;

  .refined-checkbox__options {
    display: flex;

    i {
      width: 24.5px;
      height: 22.5px;
      text-align: center;
      top: -1px;

      &:last-child {
        &:before {
          left: 1px;
        }
      }

      &:after {
        position: relative;
        left: 1px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .horizontal-divider {
      display: inline-block;
      width: 1px;
      height: 22px;
      background-color: $border-default-color;
    }
  }

  &.blue-popper-arrow {
    .popper__arrow {
      &:after {
        border-right-color: $brand-primary-color;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
label {
  &.refined-by-checkbox {
    line-height: 20px;
    height: 18px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-column-gap: 4px;
    align-items: center;
    padding: 0;
    cursor: pointer;

    .control-indicator {
      display: flex;
      position: static;
    }

    .refined-terms {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 19px;
      height: 18px;
    }

    .item-count {
      position: static !important;
    }
  }

  &.included,
  &.excluded {
    .item-count {
      background: rgba($brand-primary-color, 0.15) !important;
      border: 1px solid rgba($brand-primary-color, 0.15) !important;
    }
  }
}
</style>
