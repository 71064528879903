<template>
  <div class="sidebar-wrapper">
    <nav class="horizontal">
      <a
        v-for="tab in tabs"
        :key="tab.name"
        :href="tab.href"
        :class="{ active: tab.isActive }"
        @click.prevent="selectTab(tab)">
        {{ tab.name }}
      </a>
    </nav>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return { tabs: [] }
  },
  created() {
    this.tabs = this.$children
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        // eslint-disable-next-line
        tab.isActive = tab.href === selectedTab.href;
      })
    }
  }
}
</script>
