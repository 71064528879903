<template>
  <div>
    <el-popover
      :value="popoverOpen"
      placement="bottom-end"
      trigger="manual"
      popper-class="light download-results-form with-footer">
      <div
        v-click-outside="() => popoverOpen = false"
        class="popover-content">
        <div class="pop-over-default">
          <fieldset>
            <h3>Format</h3>
            <el-radio-group
              v-if="chartType === 'table'"
              v-model="fileType">
              <el-radio-button label="xlsx">XLSX</el-radio-button>
              <el-radio-button label="csv">CSV</el-radio-button>
            </el-radio-group>

            <el-radio-group
              v-else
              v-model="chartFileType">
              <el-radio-button label="xlsx">XLSX</el-radio-button>
              <el-radio-button label="pdf">PDF</el-radio-button>
            </el-radio-group>
          </fieldset>
          <fieldset>
            <h3>Filename</h3>
            <input
              v-model="fileName"
              type="text"
              placeholder="File Name (Optional)">
          </fieldset>
        </div>
      </div>

      <div class="popover-footer flex-container justify-right">
        <button
          class="btn btn-text secondary"
          @click="popoverOpen = false">Cancel</button>
        <button
          v-if="chartType === 'table'"
          class="btn btn-default btn-primary flex-items"
          @click="downloadTable">
          Download
        </button>
        <button
          v-else
          class="btn btn-default btn-primary flex-items"
          @click="downloadChart">
          Download
        </button>
      </div>

      <button
        slot="reference"
        :class="{ disabled }"
        class="btn btn-default btn-primary download-results"
        @click="popoverOpen = !popoverOpen">
        Download Results
      </button>
    </el-popover>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { startCase, upperCase } from 'lodash'
import jsPDF from 'jspdf'
import XLSX from 'xlsx/dist/xlsx.core.min'
import html2canvas from 'html2canvas'
import Colors from '~/utils/Colors'
import { locations, transits } from '~/config/field-categories'
import { statesKeys } from '~/utils/address'
import { toUpperFirst } from '~/utils/string'
import { miscFilters } from '~/config/config'
import { convertToTwoDigitDecimal } from '~/utils/number'
import { ig } from '~/config/logo'
import { splitAdvancedKeywords } from '~/utils/advancedSearch'

export default {
  name: 'AnalyticsDownloader',
  props: {
    chartType: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      doc: null,
      row: 25,
      fileType: 'xlsx',
      chartFileType: 'xlsx',
      popoverOpen: false,
      isGenerating: false,
      fileName: '',
      notify: false
    }
  },
  computed: {
    ...mapState('search', ['refinedBy', 'miscFilters']),
    ...mapState('accounts', ['user']),
    ...mapState('analytics', [
      'chartLegend',
      'lineChartField',
      'fieldOptions',
      'measurementOptions',
      'measurement'
    ]),
    ...mapGetters('search', ['hasSearch', 'searchKeywords']),
    ...mapGetters('analytics', [
      'chartTitle',
      'cleanedAnalyticsData',
      'pluralizedFieldLabel',
      'measurementLabel',
      'fieldLabel',
      'lineChartData',
      'lineChartTableData'
    ]),
    ...mapGetters(['arrivalDateBetween']),
    locations() {
      return this.refinedBy.filter(
        value => locations.indexOf(value.field) !== -1
      )
    },
    transits() {
      return this.refinedBy.filter(
        value => transits.indexOf(value.field) !== -1
      )
    },
    formattedAnalyticsData() {
      const formatData = data => convertToTwoDigitDecimal(data).toString()

      return this.cleanedAnalyticsData.map(data => {
        const formattedData = data
        formattedData.shipmentsCount = formatData(data.shipmentsCount)
        formattedData.containersCount = formatData(data.containersCount)
        formattedData.weightKg = formatData(data.weightKg)
        formattedData.calculatedTeu = formatData(data.calculatedTeu)

        return formattedData
      })
    },
    additionalInfo() {
      return [
        [],
        ['Report Generated by', this.user.username],
        [
          'Report Generated on',
          this.$date.format(Date.now(), 'yyyy-MM-dd hh:mm a')
        ],
        ['Search URL', window.location.href],
        ['Powered By \n https://www.importgenius.com']
      ]
    }
  },
  methods: {
    async downloadChart() {
      if (this.isGenerating) {
        return
      }

      if (this.chartFileType === 'xlsx') {
        this.exportExcel({ appendChart: true, appendSearchConditions: true })
        return
      }

      let name = this.fileName

      this.isGenerating = true
      this.doc = new jsPDF()
      this.doc.setTextColor(60, 60, 60)
      this.doc.setDrawColor(60, 60, 60)
      this.doc.setFontSize(8)
      this.appendHeader()
      this.addRow(10)
      this.appendDates()
      switch (this.chartType) {
        case 'line':
          await this.appendChart()
          this.addRow(80)
          await this.appendLineGraphTable()
          break
        case 'bar':
          await this.appendChart()
          break
        case 'pie':
          await this.appendPie()
          break
        default:
          return
      }
      this.addRow(this.chartType !== 'line' ? 80 : 20)
      this.appendTable()
      if (this.hasSearch) {
        this.addRow(15)
        this.appendKeywords()
      }

      if (name.trim().length === 0) {
        name = this.defaultFilename()
      }

      this.doc.save(`${name}.pdf`)
      this.reset()
    },
    downloadTable() {
      this.popoverOpen = false
      switch (this.fileType) {
        case 'xlsx':
          this.exportExcel()
          return
        case 'csv':
          this.exportCsv()
          return
        default:
          return
      }
    },
    defaultFilename() {
      const keyword = this.hasSearch
        ? splitAdvancedKeywords(this.searchKeywords.i[0]).keyword[0]
        : 'all'
      return `${this.$store.state.auth.user.username}_${
        this.chartType
      }_${keyword}-${new Date().getTime()}`
    },
    appendHeader() {
      this.doc.addImage(ig, 'PNG', 80, 10, 50, 10)
      this.doc.textWithLink('https://www.importgenius.com', 87.5, 25, {
        url: 'https://www.importgenius.com'
      })
    },
    appendDates() {
      const { from, to } = this.arrivalDateBetween
      this.doc.text(
        10,
        this.row,
        `Date Ranges: ${this.$date.formatUtc(
          from,
          'yyyy-MM-dd'
        )} - ${this.$date.formatUtc(to, 'yyyy-MM-dd')}`
      )
      this.doc.text(
        155,
        this.row,
        `Export Date: ${this.$date.format(
          new Date().getTime(),
          'yyyy-MM-dd hh:mm aa'
        )}`
      )
    },
    async appendChart() {
      const chartSelector = document.querySelector(`#${this.chartType}-chart`)
      const chartImage = chartSelector.toDataURL('image/png', 1)
      const titleAppend =
        this.lineChartField === 'none'
          ? ''
          : `by ${this.fieldOptions[this.lineChartField]}`
      this.addRow(10)
      this.doc.setFontType('bold')
      this.doc.text(
        10,
        this.row,
        upperCase(`${this.chartTitle} ${titleAppend}`)
      )
      this.doc.setFontType('normal')
      this.addRow(5)
      this.doc.addImage(chartImage, 'PNG', 10, this.row, 190, 60)
      if (this.chartType === 'line') {
        const legendSelector = document.querySelector('.line-chart-legend')
        const legendImage = await html2canvas(legendSelector, {
          background: '#FFF',
          type: 'dataURL'
        })
        this.doc.addImage(legendImage, 'PNG', 10, this.row + 60, 190, 8)
      }

      if (this.chartType === 'bar') {
        const legendSelector = document.querySelector(
          '.analytics-bar-chart__labels'
        )
        const legendImage = await html2canvas(legendSelector, {
          background: '#FFF',
          type: 'dataURL'
        })
        this.doc.addImage(legendImage, 'PNG', 10, this.row + 60, 190, 1.75)

        const paginationSelector = document.querySelector(
          '#analytics_pagination_label'
        )
        const paginationImage = await html2canvas(paginationSelector, {
          background: '#FFF',
          type: 'dataURL'
        })
        this.doc.addImage(paginationImage, 'PNG', 85, this.row + 67, 40, 1.9)
      }
    },
    async appendPie() {
      const chartSelector = document.querySelector(`#${this.chartType}-chart`)
      const chartImage = chartSelector.toDataURL('image/png', 1)
      this.addRow(10)
      this.doc.setFontType('bold')
      this.doc.text(10, this.row, upperCase(`${this.chartTitle} chart`))
      this.doc.setFontType('normal')
      this.addRow(5)
      this.doc.addImage(chartImage, 'PNG', 40, this.row, 100, 100)
      const chartStartRow = this.row
      this.addRow(15)
      this.doc.setFontSize(6)
      this.doc.setTextColor(200, 200, 200)
      this.doc.text(150, this.row, 'LEGEND')
      this.doc.setTextColor(60, 60, 60)
      this.chartLegend.forEach(legend => {
        this.addRow(5)
        const colorString = '' + legend.color
        const { r, g, b } = Colors.hexToRgb(colorString)
        this.doc.setFillColor(r, g, b)
        this.doc.circle(151, this.row - 0.7, 1, 'F')
        const splitLabel = this.doc.splitTextToSize(upperCase(legend.label), 50)
        this.doc.text(154, this.row, splitLabel)
      })
      this.doc.setFontSize(8)
      this.row = chartStartRow + 30
    },
    async appendLineGraphTable() {
      const { legend } = this.lineChartData

      const tableLength = []
      let noOfItems = legend.length

      while (noOfItems !== 0) {
        if (noOfItems >= 6) {
          tableLength.push(6)
          noOfItems -= 6
        } else {
          tableLength.push(noOfItems)
          noOfItems = 0
        }
      }

      this.doc.setFontType('normal')

      let width,
        i,
        label = ''

      const offsetReducer = limitIndex =>
        tableLength.reduce((acc, cur, index) => {
          if (index < limitIndex) {
            return acc + cur
          }
          return acc
        }, 0)

      tableLength.forEach((len, index) => {
        const colWidth = Math.floor(160 / len)
        let offset = index ? offsetReducer(index) : 0
        this.addRow(7)

        width = Math.max(colWidth, 70)
        this.doc.text(10, this.row, '#', 'left')
        this.doc.text(15, this.row, 'Time', 'left')
        for (i = offset; i < len + offset; i++) {
          label = this.formatString(
            colWidth - 10,
            toUpperFirst(legend[i].label)
          )
          this.doc.text(width, this.row, label, 'right')
          width += colWidth
        }

        offset += 2
        this.lineChartTableData.forEach(({ data }) => {
          this.addRow(5)
          this.doc.line(10, this.row - 3.5, 200, this.row - 3.5)
          this.doc.text(10, this.row, `${data[0].text}`, 'left')
          this.doc.text(15, this.row, `${data[1].text}`, 'left')
          width = Math.max(colWidth, 70)

          for (i = offset; i < len + offset; i++) {
            this.doc.text(width, this.row, `${data[i].text}`, 'right')
            width += colWidth
          }
        })
      })

      this.doc.setDrawColor(60, 60, 60)
    },
    formatString(colWidth, str) {
      let strLen = this.doc.getTextWidth(str)
      if (strLen < colWidth) return str

      const ellipsis = '...'
      let formattedStr = str
      let index = str.length

      while (strLen > colWidth) {
        index /= 2
        formattedStr = str.slice(0, index)
        strLen = Math.ceil(
          this.doc.getTextWidth(formattedStr + str.charAt(index) + ellipsis)
        )
      }

      while (strLen < colWidth) {
        formattedStr += str.charAt(index)
        strLen = Math.ceil(this.doc.getTextWidth(formattedStr + ellipsis))
        index++
      }

      return formattedStr + ellipsis
    },
    appendTable() {
      this.doc.setFontType('bold')
      this.doc.text(
        10,
        this.row,
        upperCase(
          `Top ${this.cleanedAnalyticsData.length} ${
            this.pluralizedFieldLabel
          } by ${this.measurementLabel} Table`
        )
      )
      this.doc.setFontType('normal')
      this.addRow(7)
      this.doc.text(10, this.row, '#', 'left')
      this.doc.text(15, this.row, this.fieldLabel, 'left')
      this.doc.text(120, this.row, 'Shipments', 'right')
      this.doc.text(140, this.row, 'Containers', 'right')
      this.doc.text(172, this.row, 'Gross Weight (Kgs)', 'right')
      this.doc.text(200, this.row, 'TEU', 'right')
      this.formattedAnalyticsData.forEach((data, index) => {
        this.addRow(5)
        this.doc.line(10, this.row - 3.5, 200, this.row - 3.5)
        this.doc.text(10, this.row, `${index + 1}`, 'left')
        this.doc.text(15, this.row, `${data.name}`, 'left')
        this.doc.text(120, this.row, data.shipmentsCount, 'right')
        this.doc.text(140, this.row, data.containersCount, 'right')
        this.doc.text(170, this.row, data.weightKg, 'right')
        this.doc.text(200, this.row, data.calculatedTeu, 'right')
      })
      this.doc.setDrawColor(60, 60, 60)
    },
    appendSheetKeywords() {
      const data = []
      const formattedDate = {
        from: this.$date.format(
          this.arrivalDateBetween.from * 1000,
          'yyyy-MM-dd'
        ),
        to: this.$date.format(this.arrivalDateBetween.to * 1000, 'yyyy-MM-dd')
      }
      const appendAdvancedGroup = keywords => {
        keywords.i.forEach(item => {
          if (item.i) {
            appendAdvancedGroup(item)
          } else {
            appendAdvancedText(item, keywords)
          }
        })
      }

      const appendAdvancedText = (condition, keywords) => {
        const row = []
        const { keyword, field, type } = condition
        let keywordText = keyword.map(item => `"${item}"`).join(', ')

        row.push(keywords.o)
        row.push(this.formatField(field))
        row.push(keywordText)
        row.push(startCase(type))

        data.unshift(row)
      }
      appendAdvancedGroup(this.searchKeywords)

      return [
        ['US Import Shipments'],
        [],
        ['Date Range', `${formattedDate.from} - ${formattedDate.to}`],
        [],
        ['Condition', 'Field', 'Keyword', 'Type'],
        ...data
      ]
    },
    appendKeywords() {
      const startRow = this.row
      this.doc.setFontType('bold')
      this.doc.text(10, this.row, 'SEARCH CRITERIA')
      this.doc.setFontType('normal')
      this.addRow(7)
      this.doc.text(10, this.row, 'KEYWORDS:')
      if (this.hasSearch) {
        this.appendAdvancedGroup(this.searchKeywords, 7)
      }
      const lastRow = this.row
      if (this.locations.length) {
        this.row = startRow
        this.addRow(7)
        this.doc.text(110, this.row, 'LOCATIONS:')
        this.locations.forEach(refinement => {
          this.addRow(5)
          this.doc.text(
            110,
            this.row,
            `${startCase(refinement.condition)} ${statesKeys[refinement.name] ||
              refinement.name} in ${this.formatField(refinement.field)}`
          )
        })
      }
      if (this.transits.length) {
        this.row = this.locations.length ? this.row : startRow
        this.addRow(7)
        this.doc.text(110, this.row, 'TRANSITS:')
        this.transits.forEach(refinement => {
          this.addRow(5)
          this.doc.text(
            110,
            this.row,
            `${startCase(refinement.condition)} ${
              refinement.name
            } in ${this.formatField(refinement.field)}`
          )
        })
      }
      if (this.miscFilters.length) {
        this.row =
          this.locations.length || this.transits.length ? this.row : startRow
        this.addRow(7)
        this.doc.text(110, this.row, 'MISCELLANEOUS:')
        this.miscFilters.forEach(filter => {
          this.addRow(5)
          this.doc.text(
            110,
            this.row,
            this.miscFilterConfig(filter).displayName
          )
        })
      }
      this.doc.setLineWidth(0.5)
      this.doc.line(
        100,
        startRow + 5,
        100,
        this.row > lastRow ? this.row : lastRow
      )
    },
    appendAdvancedGroup(keywords, column) {
      const startColumn = column + 3
      this.addRow(5)
      this.doc.text(startColumn, this.row, upperCase(keywords.o))
      keywords.i.forEach(item => {
        if (item.i) {
          this.appendAdvancedGroup(item, startColumn)
        } else {
          this.appendAdvancedText(item, startColumn)
        }
      })
    },
    appendAdvancedText(condition, column) {
      this.addRow(5)
      const { keyword, field, type } = condition
      let keywordText = keyword.map(item => `"${item}"`).join(', ')

      if (keyword.length > 1) {
        this.doc.text(column + 3, this.row, `- ${startCase(type)}`)
        this.addRow(5)
        this.doc.text(column + 5, this.row, `  ${keywordText}`)
        this.addRow(5)
        this.doc.text(column + 5, this.row, `  in ${this.formatField(field)}`)

        return
      }

      this.doc.text(
        column + 3,
        this.row,
        `- ${startCase(type)} ${keywordText} in ${this.formatField(field)}`
      )
    },
    exportExcel(options = {}) {
      const { appendSearchConditions = false } = options
      let name = this.fileName
      let sheetName = 'Import Analysis'
      let xlsxData = []

      if (name.trim().length === 0) {
        name = this.defaultFilename()
      }

      if (this.chartType === 'line') {
        xlsxData = [
          [
            'ID',
            'Time',
            ...this.lineChartData.legend.map(
              ({ label }) =>
                label === 'All'
                  ? this.measurementOptions[this.measurement]
                  : label
            )
          ],
          ...this.lineChartTableData.map(({ data }) =>
            data.map(({ text }) => {
              const validNumber =
                typeof text === 'string' ? text.split(',').join('') : false

              if (+validNumber) return +validNumber

              return text
            })
          )
        ]
      } else {
        const arrayifiedData = this.formattedAnalyticsData.reduce(
          (acc, data) => {
            const toNumber = value => +value.split(',').join('')

            acc.push([
              data.name,
              toNumber(data.shipmentsCount),
              toNumber(data.containersCount),
              toNumber(data.weightKg),
              toNumber(data.calculatedTeu)
            ])
            return acc
          },
          []
        )

        xlsxData = [
          [
            this.fieldLabel,
            'Shipments',
            'Containers',
            'Gross Weight (KG)',
            'TEU'
          ],
          ...arrayifiedData
        ]
      }

      xlsxData.push(...this.additionalInfo)

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet(xlsxData)

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

      if (appendSearchConditions && this.hasSearch) {
        sheetName = 'Filtering Conditions'
        const keywordWorkSheet = this.appendSheetKeywords()

        const chartWorksheet = XLSX.utils.aoa_to_sheet(keywordWorkSheet)
        XLSX.utils.book_append_sheet(workbook, chartWorksheet, sheetName)
      }

      XLSX.writeFile(workbook, `${name}.xlsx`)
    },
    exportCsv() {
      const header = `${
        this.fieldLabel
      },Shipments,Containers,Gross Weight (KG),TEU`
      const stringifiedData = this.formattedAnalyticsData.map(
        data =>
          `"${data.name}","${data.shipmentsCount}","${data.containersCount}","${
            data.weightKg
          }","${data.calculatedTeu}"`
      )
      const additionalInfoCSV = this.additionalInfo.map(data => {
        if (data.length === 0) return '\n'

        return `${data.join(',')}`
      })

      const csvData = [header, ...stringifiedData, ...additionalInfoCSV].join(
        '\n'
      )

      const link = document.createElement('a')
      const mimeType = 'data:text/csv;charset=utf-8'

      let name = this.fileName

      if (name.trim().length === 0) {
        name = this.defaultFilename()
      }

      if (navigator.msSaveBlob) {
        // for IE10
        navigator.msSaveBlob(
          new Blob([csvData], {
            type: mimeType
          }),
          `${name}.csv`
        )
      } else if (URL && 'download' in link) {
        link.href = URL.createObjectURL(
          new Blob([csvData], {
            type: mimeType
          })
        )
        link.setAttribute('download', `${name}.csv`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        location.href =
          'data:application/octet-stream,' + encodeURIComponent(csvData)
      }
    },
    addRow(value) {
      // if row goes over the doc's boundaries, create a new page
      if (this.row > 280) {
        this.doc.addPage()
        this.appendHeader()
        this.row = 35
        return
      }
      // else just add it to this.row
      this.row = this.row + value
    },
    reset() {
      this.doc = null
      this.row = 25
      this.isGenerating = false
    },
    miscFilterConfig(val) {
      return miscFilters[miscFilters.map(m => m.value).indexOf(val)]
    },
    formatField(field) {
      const { fields } = this.$store.state.views
      return fields[field] !== undefined
        ? fields[field].displayName
        : startCase(field)
    }
  }
}
</script>
