<template>
  <div
    class="search-bar-criteria exp-1">
    <ul
      ref="searchCriteria">
      <li
        class="card-base">
        <h4>Date Range</h4>
        <div
          class="criteria-section"
          @click="editDateRange">
          <ul class="criteria-group">
            <li
              class="edit-date-pill">
              {{ $date.formatUtc(dateRange.from, 'yyyy-MM-dd') }} - {{ $date.formatUtc(dateRange.to, 'yyyy-MM-dd') }}
              <button v-if="!isShipmentAlertSearch"><i class="edit-icon"/></button>
            </li>
          </ul>
        </div>
      </li>
      <li
        v-if="isShipmentAlertSearch"
        class="card-base" >
        <h4 >Alert Date</h4>
        <div
          class="criteria-section">
          <ul class="criteria-group">
            <li
              class="edit-date-pill">
              {{ $date.formatUtc(shipmentAlertResult.triggerDate, 'yyyy-MM-dd') }}
            </li>
          </ul>
        </div>
      </li>
      <li
        v-for="(searchKeyword, index) in searchCriteria"
        :key="index"
        class="card-base no-shadow-last">
        <h4>{{ fields[searchKeyword.field].displayName }}</h4>
        <div class="criteria-section">
          <ul class="criteria-group">
            <li
              v-for="(keyword, index) in searchKeyword.keyword"
              :key="index"
              @click="isViewOnly ? () => {} : openAdvancedSearchDrawer($event, false, searchKeyword.id)">
              {{ keyword | formatKeyword(searchKeyword.searchType) }}
              <span
                v-if="removeKeywordsAllowed && !isViewOnly"
                id="removeKeyword"
                class="removed-icon"
                @click="removeKeyword(searchKeywords, searchKeyword, index)" />
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div
      v-if="hasSearch"
      :class="{ 'fixed-position': isOverflowing }"
      class="control-options">
      <el-tooltip
        v-for="(control, index) in controlGroups"
        :key="index"
        :popper-class="popperCriteria"
        placement="left">
        <template slot="content">
          <div
            v-if="!searchCredits"
            class="popover-tooltip-notification  small-content">
            <h3>Upgrade Account</h3>
            <div class="flex-container">
              <i class="database-upgrade-icon"/>
              <p>
                <a
                  href="#"
                  class="inline-normal"
                  @click="$bus.$emit('closePopover')">Upgrade</a> your subscription to search more!</p>
            </div>
          </div>
          <div v-else>
            {{ control.text }}
          </div>
        </template>
        <div v-if="!isViewOnly">
          <button
            v-if="!control.clearAll"
            :class="{ disabled: !searchCredits }"
            @click="openAdvancedSearchDrawer($event, control.newSearch)"><i :class="control.icon"/>
          </button>
          <button
            v-else
            @click="clearSearch"><i class="removed-icon"/></button>
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'SearchCriteriaBar',
  props: {
    fields: {
      type: Object,
      required: true
    },
    searchCredits: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      keywords: [],
      updateKeywords: [],
      isOverflow: false,
      identifiers: {},
      controlGroups: [
        { text: 'New Search', icon: 'zoom-icon', newSearch: true },
        { text: 'Edit Search', icon: 'edit-icon', newSearch: false }
      ]
    }
  },
  computed: {
    ...mapGetters('search', [
      'hasSearch',
      'searchKeywords',
      'isShipmentAlertSearch',
      'shipmentAlertResult'
    ]),
    ...mapState(['dateRange']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('userSubscriptions', ['withinSubscriptionDateRange']),
    popperCriteria() {
      return this.searchCredits === 0 ? 'is-light' : 'popper-criteria'
    },
    hasKeywords() {
      return Object.keys(this.searchKeywords).length > 0
    },
    searchCriteria() {
      if (this.hasKeywords)
        return this.getSearchCriteria(cloneDeep(this.searchKeywords))
      return []
    },
    isOverflowing() {
      if (this.hasKeywords) return this.overflowContent()
      return false
    },
    removeKeywordsAllowed() {
      let allowed =
        this.searchCredits && this.withinSubscriptionDateRange(this.dateRange)

      this.searchCriteria.forEach(item => {
        allowed = allowed && this.fields[item.field].searchable
      })

      return allowed
    },
    isViewOnly() {
      return this.$route.path.includes('/shipment-alert')
    }
  },
  watch: {
    searchKeywords: {
      handler() {
        this.keywords = []
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('search', ['setSearchKeywords']),
    ...mapActions(['showErrorUserCredits']),
    ...mapActions('search', ['search', 'clearSearch']),
    overflowContent() {
      this.$nextTick(() => {
        const element = this.$refs.searchCriteria

        this.isOverflow =
          (element ? element.scrollWidth : null) >
          (element ? element.offsetWidth : null)
      })
      return this.isOverflow
    },
    getSearchCriteria(keywords) {
      this.identifiers = this.getGroupCriteria(keywords)
      return this.keywords
    },
    getGroupCriteria(condition) {
      const keywordsArray = condition.i
      const type = 'condition'

      const ids = keywordsArray.map(condition => {
        if (condition.i) {
          return this.getGroupCriteria(condition)
        } else {
          return this.getKeyword(condition)
        }
      })

      return { type, ids }
    },
    getKeyword(condition) {
      const identifier = {
        type: 'keyword',
        id: condition.id,
        field: condition.field,
        keyword: condition.keyword,
        searchType: condition.type
      }

      this.keywords.push(identifier)
      return identifier
    },
    openAdvancedSearchDrawer(event, newSearch, keywordId = null) {
      if (event && event.srcElement.id === 'removeKeyword') return

      if (!this.searchCredits) {
        this.showErrorUserCredits()
        return
      }

      this.$bus.$emit('open-advanced-search-drawer', { newSearch, keywordId })
    },
    editDateRange() {
      if (this.isShipmentAlertSearch) return

      this.$bus.$emit('open-date-picker')
      this.$bus.$emit('open-advanced-search-drawer', {
        newSearch: false,
        focusInput: false
      })
    },
    removeKeyword(searchKeywords, searchKeyword, keywordIndex) {
      const { keywords = {}, identifiers = {} } = this.recursiveRemove(
        cloneDeep(searchKeywords),
        cloneDeep(this.identifiers),
        searchKeyword.id,
        keywordIndex
      )

      this.identifiers = identifiers

      if (keywords && ['AND', 'OR', 'NOT'].includes(keywords.o)) {
        this.setSearchKeywords(keywords)
        this.search().then(() => this.$router.push(this.currentSearchURL))
        return
      }

      this.clearSearch()
    },
    recursiveRemove(keywords, identifiers, keywordId, keywordIndex) {
      let updatedKeywords = [],
        updatedIdentifiers = []

      identifiers.ids.forEach((item, index) => {
        if (item.type === 'condition') {
          const { keywords: keyword, identifiers: id } = this.recursiveRemove(
            keywords.i[index],
            item,
            keywordId,
            keywordIndex
          )

          if (keyword) updatedKeywords.push(keyword)
          if (id) updatedIdentifiers.push(id)
        } else {
          if (item.id === keywordId) {
            item.keyword.splice(keywordIndex, 1)
            keywords.i[index].keyword.splice(keywordIndex, 1)
          }

          if (item.keyword.length) {
            updatedKeywords.push(keywords.i[index])
            updatedIdentifiers.push(item)
          }
        }
      })

      if (!updatedIdentifiers.length) return {}

      return {
        keywords: { o: keywords.o, i: updatedKeywords },
        identifiers: { type: identifiers.type, ids: updatedIdentifiers }
      }
    }
  }
}
</script>
<style src="~/assets/scss/sections/searchbar-criteria/search-bar-criteria.scss" lang="scss"/>
