<template>
  <fieldset
    :class="{
      warning,
      'multi-select-active': isMultipleSelected,
      'active': (isFocus && inputFieldsShown),
      'first-condition': firstCondition,
      'last-condition': !inputFieldsShown,
      'input-warning': inputWarning
  }">
    <div
      v-if="!firstCondition"
      class="logic-control">
      <el-radio-group
        v-model="searchCondition"
        size="medium"
        class="el-radio-group el-radio-button-group"
        @change="changeSearchCondition">
        <template v-for="condition in conditionList">
          <el-radio-button
            :label="condition"
            :key="condition"/>
        </template>
      </el-radio-group>
    </div>
    <condition
      v-show="inputFieldsShown"
      ref="condition"
      :default-field="defaultField"
      :first-condition="firstCondition"
      :search-condition="searchCondition"
      :input-fields-shown="inputFieldsShown"
      @warning="setWarning"
      @focus="setToActive"
      @remove="$emit('remove')"
      @multiselect="handleMultiselect"
      @input-enter="$emit('search')"
      @invalid-input="setInputWarning"/>
  </fieldset>
</template>

<script>
import Condition from '~/components/layouts/Sidebar/AdvancedSearchDrawer/Condition'

export default {
  name: 'ConditionGroup',
  components: {
    Condition
  },

  props: {
    firstCondition: {
      type: Boolean,
      default: false
    },

    defaultField: {
      type: String,
      default: 'Consignee'
    }
  },

  data() {
    return {
      searchCondition: '',

      error: false,
      warning: false,
      inputWarning: false,
      isFocus: false,
      clickedNewCondition: false,
      conditionList: ['AND', 'OR', 'NOT'],
      isMultipleSelected: false
    }
  },
  computed: {
    inputFieldsShown() {
      return this.firstCondition ? true : !!this.searchCondition
    }
  },
  methods: {
    changeSearchCondition() {
      if (!this.firstCondition) {
        this.$emit('hide-tips')
      }

      if (!this.clickedNewCondition) {
        this.$emit('addcondition')
        this.clickedNewCondition = true
        return
      }

      if (this.getValue()) {
        this.$bus.$emit('condition-changed')
      }
    },

    setValue(value) {
      this.searchCondition = value.o || 'AND'
      this.clickedNewCondition = !!this.searchCondition

      if (this.inputFieldsShown) {
        this.$refs.condition.setValue(value.i[0])
      }
    },

    getValue(option) {
      if (!this.inputFieldsShown) {
        // value for not displayed conditions
        return { o: '' }
      }

      const condition = this.$refs.condition.getValue(option)

      if (!condition) return

      return {
        o: this.searchCondition,
        i: condition
      }
    },

    validate(option) {
      if (!this.inputFieldsShown) return true

      return this.$refs.condition.validate(option)
    },

    setWarning(warning) {
      this.warning = warning
    },

    setInputWarning(value) {
      this.inputWarning = value
    },

    setToActive(isFocus) {
      this.isFocus = isFocus
    },

    handleMultiselect(isMultipleSelected) {
      this.isMultipleSelected = isMultipleSelected
    },

    focus() {
      this.$refs.condition.focus()
    },

    isInputEmpty() {
      if (!this.inputFieldsShown) return true

      return this.$refs.condition.isInputEmpty()
    }
  }
}
</script>
