<template>
  <div
    :class="{
      'collapsed-sidebar': collapsedSidebar,
      'height-auto': (dataTableTotal > 5)
    }"
    class="search-container">

    <!-- Error alert banner -->
    <alert-banner
      v-if="error.show && error.message"
      id="search-credit-alert"
      :animate="{from: 'top', type:'slide'}"
      :has-icon="true"
      :alert-message="error.message"
      alert-type="warning"
      class="alert-floated-fixed bottom-shadow"
      animation
      is-dismissible
      @close-alert-banner="closeAlertBanner">
      <p v-if="error.message === 'Daily search limit exceeded'">
        You've hit your limit of {{ searches.limit }} daily searches under your current plan.
        Your search limits will refresh in {{ countDownRefreshMsg }}.

        <span v-if="!hasPremiumSubscription">
          <a
            href="#"
            class="alert-link"
            @click.prevent="openUpgradeModal">Upgrade</a> your account to get more searches per day.
        </span>
      </p>
      <p v-else-if="error.type === 'subscription'">
        {{ error.message | firstSentenceOnly }}
        <span v-if="!hasPremiumSubscription">
          <a
            href="#"
            class="alert-link"
            @click.prevent="openUpgradeModal">Upgrade</a> now to access <nuxt-link
              :to="`/search/${error.searchId}?view=overview`"
              style="color:inherit">this search</nuxt-link>.
        </span>
      </p>
      <p v-else>
        {{ error.message }}
      </p>
    </alert-banner>

    <div class="flex-container">
      <sidebar
        :sidebar="collapsedSidebar"
        @show="displaySidebar"/>

      <section
        :class="{ 'custom-z-index': isCustomViewOpen }"
        class="section-content search-content">
        <div class="section-inner">
          <!-- Sidebar collapse/expand button -->
          <span
            v-if="!isCustomViewOpen"
            :class="{
              'move-right': isSidebarContentOpen
            }"
            class="collapsed-button"
            @click="showSidebar">
            <span
              v-if="currentSearchId"
              class="circle">
              <i class="caret-icon left-caret"/>
            </span>
          </span>

          <!-- Search Drawer -->
          <advanced-search-drawer v-show="isSidebarContentOpen" />

          <div class="search-page tabcontent">
            <!-- Shipment search area -->
            <search-data-viewer />
          </div>
        </div>
      </section>
    </div>

    <section class="section-footer pull-right">
      Copyright &copy; {{ thisYear }}. All Rights Reserved. ImportGenius
    </section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import SearchDataViewer from '~/components/data-viewers/SearchDataViewer'
import AdvancedSearchDrawer from '~/components/layouts/Sidebar/AdvancedSearchDrawer/AdvancedSearchDrawer'
import FirstLoginTour from '~/components/tours/FirstLoginTour'
import AlertBanner from '~/components/common/AlertBanner'
import Sidebar from '~/components/layouts/Sidebar'
import { upgradeModal } from '~/mixins'

export default {
  /**
   * Search page component
   */
  name: 'SearchId',
  middleware: ['analyticsSubscriptionIsRequired'],

  beforeRouteLeave(to, from, next) {
    if (this.$refs.FirstLoginTour) {
      this.$refs.FirstLoginTour.beforeLeave()
    }
    next()
  },

  components: {
    SearchDataViewer,
    FirstLoginTour,
    Sidebar,
    AlertBanner,
    AdvancedSearchDrawer
  },

  filters: {
    /**
     * Return the first sentence of the message
     */
    firstSentenceOnly(message) {
      const indexFirstSentence = message.indexOf('.') + 1
      return indexFirstSentence ? message.slice(0, indexFirstSentence) : message
    }
  },

  mixins: [upgradeModal],

  data() {
    return {
      /**
       * Indicator if sidebar is collapsed or expanded
       */
      collapsedSidebar: false
    }
  },

  computed: {
    ...mapState('search', [
      'isCustomViewOpen',
      'shipments',
      'currentSearchId',
      'currentPage',
      'totalShipments',
      'pageLimit',
      'isComponentLoading'
    ]),
    ...mapState('views', ['activeAggregateView']),
    ...mapState([
      'error',
      'countDownRefreshMsg',
      'isSidebarContentOpen',
      'isBetaModalOpen'
    ]),
    ...mapState('auth', ['user']),
    ...mapState('tours', ['FirstLoginTour']),
    ...mapState('userCredits', ['searches']),
    ...mapState('search/aggregatedConsignees', ['totalConsignees']),
    ...mapState('search/aggregatedShippers', ['totalShippers']),
    ...mapGetters('userSubscriptions', ['hasPremiumSubscription']),
    ...mapGetters(['currentSearchURL']),
    ...mapGetters('search', ['activeViewCurrentPage', 'activeViewPageLimit']),

    // Current year
    thisYear() {
      return new Date().getFullYear()
    },

    // Current valid url params
    activeViewQueryParams() {
      return {
        view: this.activeAggregateView,
        p: this.activeViewCurrentPage,
        r: this.activeViewPageLimit
      }
    },

    // Checker if process for fetching shipments data is still going
    shipmentWasSearched() {
      return this.$actions.pending('search/fetchShipments')
    },

    // total count of data shown in current view
    dataTableTotal() {
      switch (this.activeAggregateView) {
        case 'consignees':
          return this.totalConsignees
        case 'shippers':
          return this.totalShippers
        default:
          return this.totalShipments
      }
    }
  },

  watch: {
    isComponentLoading(isLoading) {
      if (isLoading) {
        document.documentElement.style.overflow = 'hidden'
        return
      }
      document.documentElement.style.overflow = 'auto'
    },
    activeViewQueryParams() {
      this.updateQueryParams()
    },
    shipmentWasSearched(to) {
      if (to === false) this.updateQueryParams()
    },

    // do not allow scrolling if search drawer is open
    isSidebarContentOpen(isOpen) {
      const root = document.getElementsByTagName('html')[0]
      if (isOpen) {
        root.classList.add('overflow-hidden')
        root.style.height = null
        return
      }

      root.classList.remove('overflow-hidden')
      if (
        this.$route.query.view === 'analytics' ||
        this.$route.query.view === 'overview'
      )
        root.style.height = 'auto'
    },

    // update data table if route params change
    async $route(to) {
      const { view, p: pageNumber, r: limit } = to.query
      const { searchId: newSearchId } = to.params

      if (view !== this.activeAggregateView) {
        this.setActiveAggregateView(view)
      }

      if (
        (!!newSearchId || !!this.currentSearchId) &&
        newSearchId !== this.currentSearchId
      ) {
        this.commitActiveViewPageLimit(parseInt(limit))
        if (newSearchId) {
          await this.fetchShipmentsBySearchId({
            searchId: newSearchId,
            p: parseInt(pageNumber)
          })
          this.setInitializingPage(false)
          return
        }
        await this.clearSearch(pageNumber)
        this.setInitializingPage(false)
      } else if (
        newSearchId === this.currentSearchId &&
        (parseInt(pageNumber) !== this.activeViewCurrentPage ||
          parseInt(limit) !== this.activeViewPageLimit)
      ) {
        this.commitActiveViewPageLimit(parseInt(limit))
        this.commitActiveViewCurrentPage(parseInt(pageNumber))
      } else if (
        !newSearchId &&
        !this.currentSearchId &&
        (parseInt(pageNumber) !== this.activeViewCurrentPage ||
          parseInt(limit) !== this.activeViewPageLimit)
      ) {
        // in this case, page limit must always be set before the page
        this.commitActiveViewPageLimit(parseInt(limit))
        this.commitActiveViewCurrentPage(parseInt(pageNumber))
      }
    }
  },

  created() {
    if (!this.isBetaModalOpen) {
      this.toggleTour({
        tour: 'FirstLoginTour',
        show: !this.user.login_tour_state || this.FirstLoginTour.show
      })
    }
    if (!this.currentSearchId) {
      this.setSidebarContent(true)
    }
  },

  mounted() {
    this.updateQueryParams()

    // do not allow scrolling if search drawer is open
    if (this.isSidebarContentOpen) {
      const root = document.getElementsByTagName('html')[0]
      root.classList.add('overflow-hidden')
    }

    this.$bus.$on('show', this.displaySidebar)
  },

  updated() {
    this.updateQueryParams()
  },

  destroyed() {
    this.$bus.$off('show', this.displaySidebar)
  },

  methods: {
    ...mapActions('search', [
      'fetchShipmentsBySearchId',
      'clearSearch',
      'commitActiveViewCurrentPage',
      'commitActiveViewPageLimit'
    ]),
    ...mapMutations(['setError', 'setRootLink', 'setSidebarContent']),
    ...mapMutations('tours', ['toggleTour']),
    ...mapMutations('search', ['setInitializingPage']),
    ...mapMutations('views', ['setActiveAggregateView']),

    /**
     * Update the URL with the generated URL from the current page, limit, and search Id
     */
    updateQueryParams() {
      if (this.$actions.pending('search/fetchShipments')) return

      this.$router.push(this.currentSearchURL)
      this.setRootLink(this.currentSearchURL)
    },

    /**
     * Close the error alert banner
     */
    async closeAlertBanner() {
      this.setError({ show: false, message: null })
    },

    /**
     * Collapse or expand the sidebar
     */
    displaySidebar(forceOpen = false) {
      if (forceOpen) {
        this.collapsedSidebar = false
        return
      }

      if (this.isSidebarContentOpen && !this.collapsedSidebar) {
        this.setSidebarContent(false)
      }
      this.collapsedSidebar = !this.collapsedSidebar
    },

    /**
     * Action when the sidebar collapse/expand icon is clicked
     *
     * Close the search drawer if it's open, else
     * collapse or expand the sidebar
     */
    showSidebar() {
      if (this.isSidebarContentOpen) {
        this.setSidebarContent(false)
        return
      }

      this.displaySidebar()
    }
  }
}
</script>
